import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteServiceService } from 'src/app/_services/quote-service.service';

@Component({
  selector: 'app-care-cosmetic-repair',
  templateUrl: './care-cosmetic-repair.component.html',
  styleUrls: ['./care-cosmetic-repair.component.less']
})
export class CareCosmeticRepairComponent implements OnInit {

  constructor( private router: Router, private quoteSvc: QuoteServiceService) { }
  @Input()
  showBtn:string = '';

  ngOnInit(): void {
  }

  goToProduct(product:string){
    // this.quoteSvc.product = product;
    sessionStorage.setItem("_ins_prod", product);
    this.router.navigate(['/request-quote']);
  }

}
