import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';
import { RouterModule } from '@angular/router';
import { AppRoutingModule, routes } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './_components/home/home.component';
import { HeaderComponent } from './_components/header/header.component';
import { FooterComponent } from './_components/footer/footer.component';
import { FinanceComponent } from './_components/finance/finance.component';
import { CalculatorComponent } from './_components/calculator/calculator.component';
import { InsuranceComponent } from './_components/insurance/insurance.component';
import { AuctionsComponent } from './_components/auctions/auctions.component';
import { DealersComponent } from './_components/dealers/dealers.component';
import { FaqsComponent } from './_components/faqs/faqs.component';
import { InstalmentSaleComponent } from './_components/finance/instalment-sale/instalment-sale.component';
import { EdgeComponent } from './_components/finance/edge/edge.component';
import { ComprehensiveCoverComponent } from './_components/insurance/comprehensive-cover/comprehensive-cover.component';
import { TopUpCoverComponent } from './_components/insurance/top-up-cover/top-up-cover.component';
import { CareCosmeticRepairCoverComponent } from './_components/insurance/care-cosmetic-repair-cover/care-cosmetic-repair-cover.component';
import { TyreRimCoverComponent } from './_components/insurance/tyre-rim-cover/tyre-rim-cover.component';
import { ExtendedWarrantyCoverComponent } from './_components/insurance/extended-warranty-cover/extended-warranty-cover.component';
import { TermsConditionsComponent } from './_components/terms-conditions/terms-conditions.component';
import { PrivacyStatementComponent } from './_components/privacy-statement/privacy-statement.component';
import { LegalNoticeComponent } from './_components/legal-notice/legal-notice.component';
import { PageNotFoundComponent } from './_components/page-not-found/page-not-found.component';

import { ContactUsComponent } from './_components/contact-us/contact-us.component';
import { InsuranceTeasersComponent } from './_components/insurance/insurance-teasers/insurance-teasers.component';
import { TopUpTyreRimExtendedComponent } from './_components/contact-us/top-up-tyre-rim-extended/top-up-tyre-rim-extended.component';
import { ComprehensiveComponent } from './_components/contact-us/comprehensive/comprehensive.component';
import { CareCosmeticRepairComponent } from './_components/contact-us/care-cosmetic-repair/care-cosmetic-repair.component';
import { RequestQuoteComponent } from './_components/request-quote/request-quote.component';
import { HttpClientModule } from '@angular/common/http';
import { CookiePolicyComponent } from './_components/cookie-policy/cookie-policy.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { WebchatTermsComponent } from './_components/webchat-terms/webchat-terms.component';
import { PaiaAndPopiaComponent } from './_components/paia-and-popia/paia-and-popia.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    FinanceComponent,
    CalculatorComponent,
    InsuranceComponent,
    AuctionsComponent,
    DealersComponent,
    FaqsComponent,
    InstalmentSaleComponent,
    EdgeComponent,
    ComprehensiveCoverComponent,
    TopUpCoverComponent,
    CareCosmeticRepairCoverComponent,
    TyreRimCoverComponent,
    ExtendedWarrantyCoverComponent,
    TermsConditionsComponent,
    PrivacyStatementComponent,
    LegalNoticeComponent,
    PageNotFoundComponent,
    ContactUsComponent,
    InsuranceTeasersComponent,
    TopUpTyreRimExtendedComponent,
    ComprehensiveComponent,
    CareCosmeticRepairComponent,
    RequestQuoteComponent,
    CookiePolicyComponent,
    WebchatTermsComponent,
    PaiaAndPopiaComponent
  ],
  exports: [RouterModule],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SwiperModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
