<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/extended-warranty-cover.jpg" alt="ALPHERA Extended Warranty Cover"
                class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Extended Warranty Cover</h1>
                        <p>Enjoy the journey with the peace of mind of knowing you’re covered even after your
                            manufacturer warranty has expired.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/insurance">Insurance</a></li>
                        <li><a routerLink="/insurance/extended-warranty-cover">Extended Warranty Cover</a></li>
                    </ul>
                </div>
                <!-- <h3>Ease your mind</h3>
                <p>Driving is one of life’s pleasures however heavy mileage and asset ageing may cause mechanical damage
                    to your most prized possession. Fortunately, ALPHERA Extended Warranty Cover will ensure that
                    mechanical damages are minimised by providing you with a selection of cover options that shield you
                    from unexpected expenses once your manufacturer warranty has expired.</p> -->
            </div>
        </div>
    </section>

    <section class="pTB">
        <div class="container">
            <div class="containerWrapper">
            <div class="row justify-content-between align-items-center teaserHorizontal">
                <div class="col-md-5">
                    <img src="assets/img/protected.jpg" alt="Protected on the road ahead " class="img-fluid">
                </div>
                <div class="col-md-7">
                    <div class="teaserCopy pS">
                        <h3>Protected on the road ahead </h3>
                        <p>
                            Why end your journey when there are more places to go? Extend your warranty and experience
                            peace of mind with the following benefits:</p>
                        <ul>
                            <li>24-month warranty extension</li>
                            <li>No waiting period applies</li>
                            <li>42 major components included</li>
                            <li>Resultant damage or loss to an insured component caused by the failure of an insured or
                                non-insured component is covered</li>
                            <li>Cover can be transferred with the vehicle</li>
                        </ul>

                        <a href="javascript:void(0);" (click)="goToProduct('Extended Warranty Cover')" class="button button-g-blue mb-3">Request Quote</a>
                        <a href="./assets/files/ALPHERA_Extended_Warranty_Brochure.pdf" class="button button-ghost" target="_blank"> Download Brochure</a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bgPattern pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <app-insurance-teasers [insuranceTypeToChild]="'extended-warranty-cover'"></app-insurance-teasers>
            </div>
        </div>
    </section>

    <section class="bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Need a quote or support?</h3>
                <p>Whether it’s a custom quote for the insurance product you have your eye on, or you need a support
                    from our experienced insurance team, we are here to assist you on your journey. Please don’t
                    hesitate to get in touch for any questions, enquires or feedback. We are looking forward to hearing
                    from you.</p>

                <div class="col-md-6">
                    <app-top-up-tyre-rim-extended [showBtn]="'Yes'" [incomingProduct]="'Extended Warranty Cover'"></app-top-up-tyre-rim-extended>
                </div>
            </div>
        </div>
    </section>

</main>