import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-insurance-teasers',
  templateUrl: './insurance-teasers.component.html',
  styleUrls: ['./insurance-teasers.component.less']
})
export class InsuranceTeasersComponent implements OnInit {

  constructor(private helperSVC: HelperService) { }
  @Input()
  insuranceTypeToChild: any;

  ngOnInit(): void {
    //this.helperSVC.setPageName(`'Insurance-Teasers | BMW Aphera'`);

  }

}
