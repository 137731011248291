import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-edge',
  templateUrl: './edge.component.html',
  styleUrls: ['./edge.component.less']
})
export class EdgeComponent implements OnInit {

  constructor(private helperSVC: HelperService) { 

  }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Finance-Edge | BMW Aphera'`);
  }
}
