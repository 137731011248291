<main *ngIf="parent">
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/insurance.jpg" alt="" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Insurance Cover</h1>
                        <p>Enjoy access to an extensive range of insurance solutions tailored to you and your vehicle.
                            From comprehensive cover to more specialised insurance products, with ALPHERA Insurance -
                            you’re covered. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/insurance">Insurance</a></li>
                    </ul>
                </div>
                <!-- <h3>Enjoy peace of mind with a range of insurance cover </h3>
                <p>Take advantage of insurance that’s tailored to you and your vehicle with a wide range of insurance
                    solutions exclusive to ALPHERA Financial Services. Whether you’re looking for a comprehensive
                    solution or perhaps cover that is more targeted - we’ve got you covered. Here, you can browse the
                    range of cover available and learn more about each option.</p> -->
            </div>
        </div>
    </section>

    <section class="teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <app-insurance-teasers></app-insurance-teasers>
            </div>
        </div>
    </section>


    <section class="bgPattern bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row containerWrapper justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5">
                        <img src="assets/img/finance.jpg" alt="ALPHERA Finance" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pS">
                            <h2>ALPHERA Finance Products</h2>
                            <p>ALPHERA Financial Services is a leading provider of multi-brand vehicle finance products.
                                Innovative finance solutions for new and quality pre-owned vehicles enable our partners
                                to offer finance terms tailored to you.</p>

                            <a routerLink="/finance" class="button button-g-blue">Explore Finance </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>

<router-outlet></router-outlet>