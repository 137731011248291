import { Component, OnInit } from '@angular/core';
import { StructuredDataService } from './_services/structured-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'alphera-finance';

  constructor(private structuredDataSvc: StructuredDataService) {

  }

  ngOnInit() {
    this.addFaqStructuredData();
  }

  addFaqStructuredData() {
    let structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
            {
              "@type": "Question",
              "name": "How does the VAT (value-added tax) increase affect my existing finance agreement?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<strong>Individual Customers:</strong> <br>In line with the announcement by The National Treasury of a 1% increase in VAT (value-added tax) from 14% to 15% effective 1 April 2018, the monthly service fee will be adjusted from R57.00 to R57.50 or from R68.40 to R69.00 depending on the start date of your finance agreement. Accordingly, your monthly instalment will be adjusted. Please do not hesitate to get in touch on 0861 257 437 or Customer.Service@alpherafinance.co.za should you have any further enquires. <br><br><strong>Corporate Customers:</strong> <br>In line with the announcement by The National Treasury of a 1% increase in VAT (value-added tax) from 14% to 15% effective 1 April 2018, the monthly service fee will be adjusted, accordingly, your monthly instalment will be adjusted. Please do not hesitate to get in touch on 0861 257 437 or Customer.Service@alpherafinance.co.za should you have any further enquires."
              },
              "category":"GeneralInformation"
            },
            {
              "@type": "Question",
              "name": "Early settlement?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You may settle your agreement prior to the initial expiry date on your contract, however depending on the contract type and size, you may be required to pay up to 90 days’ worth of early termination penalty interest – this is governed by the National Credit Act."
              },
              "category":"GeneralInformation"
            },
            {
              "@type": "Question",
              "name": "Interest Calculation Methodology?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "ALPHERA Financial Services’ finance payment calculation uses 12 (twelve) equal periods per year, i.e. 30/360 per period which is being applied to the early termination charge calculations. This calculation is in line with the National Credit Act, No. 34 of 2005."
              },
              "category":"GeneralInformation"
            },
            {
              "@type": "Question",
              "name": "How do I obtain my registration documents once my account is paid in full?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You have 3 options:<br> <ul><li>Collection (turnaround time of 24-48 hours)</li><li>Courier (fee of R50.32)</li><li>Registered mail</li></ul><br>Please e-mail your request to customer.service@ALPHERAfinance.co.za with your preferred option together with an address of where the documents are to be sent. Company requests must be on an official letterhead if the account is in the company name."
              },
              "category":"GeneralInformation"
            },
            {
              "@type": "Question",
              "name": "Do I need any documents when crossing the border with a financed vehicle? ",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, a cross-border letter must be obtained. A written request must be sent to Customer.Service@ALPHERAfinance.co.za stating the following:<br><ul><li>The country to be visited.</li><li>Date of departure and return.</li><li>The latest proof of insurance confirming cover outside South Africa (and the countries that are to be visited) and dates of cover.</li></ul><br><br>Please note that a 72-hour turnaround time is applicable and the account must be at least 6 months old and up to date. Extreme circumstances will be considered however subject to approval."
              },
              "category":"GeneralInformation"
            },
            {
              "@type": "Question",
              "name": "Can I make additional payments on my account?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, additional payments can be made and used to reduce the instalment, term or residual. However, the client must confirm in writing how the specified funds must be allocated and declare the source of funds (FICA Anti-Money Laundering regulation). Please note that the adjustment is subject to the deal structure. There are no penalties for making additional payments."
              },
              "category":"GeneralInformation"
           
        },

        {

              "@type": "Question",
              "name": "What are the minimum requirements to apply for vehicle finance through ALPHERA Financial Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<ul><li>You (the applicant) need to be a South African citizen or have a valid permit to live and work in South Africa.</li><li>You (the applicant) need to have a valid South African driver's licence. International driver’s licenses can be accepted with certain conditions attached.</li><li>You (the applicant) need to supply comprehensive proof of income.</li><li>You (the applicant) need to have a clear credit record.</li><li>You (the applicant) need to earn a Gross Monthly Income in excess of R10 000 per month.</li><li>The amount financed needs to be more than R50 000.</li><li>The age of the vehicle at the end of the contract term must not exceed 12 years.</li></ul>"
              },
              "category":"PersonalFinance"
            },
            {
              "@type": "Question",
              "name": "Once my finance is approved, what documents will I need to take delivery of my asset?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<ul><li>Your original ID document, original driver′s licence and proof of income.</li><li>Proof of comprehensive insurance.</li><li>Any other documents as requested for at the approval stage.</li></ul>"
              },
              "category":"PersonalFinance"
            },
            {
              "@type": "Question",
              "name": "I want to purchase a vehicle privately, will you finance me?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We will finance any vehicle currently financed with us, or, if the seller or buyer is a good existing client of ALPHERA Financial Services, MINI Financial Services or BMW Financial Services."
              },
              "category":"PersonalFinance"
            },
            {
              "@type": "Question",
              "name": "What types of vehicles does ALPHERA finance?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "ALPHERA Financial Services finances all new and used leading car and motor bike brands that comply with below mentioned criteria:<br><ul><li>The total age of the car at the end of the contract term must not exceed 12 years.</li><li>GVM (Gross Vehicle Mass) must not exceed 4 000 kg’s.</li><li>The vehicle must not be registered as a “Code 3 or 4” on the eNaTIS system.</li></ul>"
              },
              "category":"PersonalFinance"
            },
            {
              "@type": "Question",
              "name": "Can I apply for finance online?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. We offer an online finance application which provides you with feedback at completion of the application."
              },
              "category":"PersonalFinance"
        },

        {
              "@type": "Question",
              "name": "What documents are required to apply for finance in a company name?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<ul><li>Completed Company Application.</li><li>Completed Individual Finance Application for owners and shareholders.</li><li>Copy of valid ID document and driver′s licence.</li><li>Proof of business address (utility account).</li><li>Company Registration documents.</li><li>Latest Annual Financial Statements.</li><li>Latest 3 months bank statements in the company name.</li></ul>"
              },
              "category":"CompanyFinance"
        }]
    }

    this.structuredDataSvc.addStructuredData(structuredData);
  }

}
