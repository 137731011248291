import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactsService } from 'src/app/_services/contacts.service';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.less']
})
export class DealersComponent implements OnInit {

  constructor( 
    private contactSVC: ContactsService,
    private helperSVC: HelperService
  ) { }

  request_result: string = '';
  alertMsg: string = '';
  alertResponse: string = '';
  submitted: boolean = false;
  isHidden: boolean = true;
  showInsuranceField: boolean = false;
  selecttedComms: string = '';
  selectedSubject: string = '';

  contactForm = new FormGroup({
    title: new FormControl('', Validators.required),
    firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z\s\p{P}]+$/u)]),
    surname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z\s\p{P}]+$/u)]),
    eMail: new FormControl('', [Validators.required, Validators.email]),
    prefTelephoneType: new FormControl('', Validators.required),
    cellphone: new FormControl(''),
    homeTelephone: new FormControl(''),
    workTelephone: new FormControl(''),
    dealerName: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required),
    dealerCity: new FormControl('', Validators.required),
    enquiry: new FormControl('', Validators.required),
  });

  get f() {
    return this.contactForm.controls;
  }
  ngOnInit(): void {
    this.helperSVC.setPageName(`'Dealers | BMW Aphera'`);
  }

  submitForm() {
    this.submitted = true;
    if (this.contactForm.invalid) {

      return;
    }
    else {
      this.contactSVC.contactsDealer(this.contactForm.value).subscribe((data)=>{
        if(data.message === 'success') {
          this.resetForm();
          let Msg = 'Thank you, your dealer enquiry has been submitted.';          
          this.showMessage(Msg, data.message)
        }
      })
    }
  }

  resetForm(value: any = undefined): void {
    this.contactForm.reset(value);
    (this as { submitted: boolean }).submitted = false;
    this.request_result = '';
    this.alertMsg = '';
  }

  toggleVisibility(event: any) {
    this.isHidden = false;
    this.selecttedComms = event.target.value;
    // let phoneField = this.contactForm.get('prefTelephoneType');
    if (this.selecttedComms == 'Home telephone') {
      this.removeValidator(this.contactForm, 'cellphone');
      this.removeValidator(this.contactForm, 'workTelephone');
      this.contactForm.get('homeTelephone')?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else if (this.selecttedComms == 'Work telephone') {
      this.removeValidator(this.contactForm, 'cellphone');
      this.removeValidator(this.contactForm, 'homeTelephone');
      this.contactForm.get('workTelephone')?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else if (this.selecttedComms == 'Cellphone') {
      this.removeValidator(this.contactForm, 'workTelephone');
      this.removeValidator(this.contactForm, 'homeTelephone');
      this.contactForm.get('cellphone')?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else {
      this.removeValidator(this.contactForm, 'cellphone');
      this.removeValidator(this.contactForm, 'workTelephone');
      this.removeValidator(this.contactForm, 'homeTelephone');
    }
  }

  showMessage(message: string, status:string){
    if(message!=''){
    this.alertMsg = message;
    }
  
    if(status!=''){
    this.alertResponse = status;
    }

    setTimeout(()=>{
      this.alertMsg = '';
      this.alertResponse  = '';
    }, 10000);
  }

  removeValidator(form:FormGroup, control:string){
    form.get(control)?.clearValidators();
    form.get(control)?.updateValueAndValidity();
    form.get(control)?.reset('');
  }
    

}
