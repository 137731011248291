import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-tyre-rim-cover',
  templateUrl: './tyre-rim-cover.component.html',
  styleUrls: ['./tyre-rim-cover.component.less']
})
export class TyreRimCoverComponent implements OnInit {

  constructor(
    private router: Router,
    private helperSVC: HelperService
  ) { }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Insurance-Tyre-Rim-Cover | BMW Aphera'`);
  }

  goToProduct(product:string){
    // this.quoteSvc.product = product;
    sessionStorage.setItem("_ins_prod", product);
    this.router.navigate(['/request-quote']);
  }
}
