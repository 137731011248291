import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './_components/home/home.component';
import { FinanceComponent } from './_components/finance/finance.component';
import { EdgeComponent } from './_components/finance/edge/edge.component';
import { InstalmentSaleComponent } from './_components/finance/instalment-sale/instalment-sale.component';
import { AuctionsComponent } from './_components/auctions/auctions.component';
import { CalculatorComponent } from './_components/calculator/calculator.component';
import { DealersComponent } from './_components/dealers/dealers.component';
import { FaqsComponent } from './_components/faqs/faqs.component';
import { LegalNoticeComponent } from './_components/legal-notice/legal-notice.component';
import { PrivacyStatementComponent } from './_components/privacy-statement/privacy-statement.component';
import { TermsConditionsComponent } from './_components/terms-conditions/terms-conditions.component';
import { ComprehensiveCoverComponent } from './_components/insurance/comprehensive-cover/comprehensive-cover.component';
import { CareCosmeticRepairCoverComponent } from './_components/insurance/care-cosmetic-repair-cover/care-cosmetic-repair-cover.component';
import { ExtendedWarrantyCoverComponent } from './_components/insurance/extended-warranty-cover/extended-warranty-cover.component';
import { TopUpCoverComponent } from './_components/insurance/top-up-cover/top-up-cover.component';
import { TyreRimCoverComponent } from './_components/insurance/tyre-rim-cover/tyre-rim-cover.component';
import { PageNotFoundComponent } from './_components/page-not-found/page-not-found.component';
import { InsuranceComponent } from './_components/insurance/insurance.component';
import { ContactUsComponent } from './_components/contact-us/contact-us.component';
import { RequestQuoteComponent } from './_components/request-quote/request-quote.component';
import { CookiePolicyComponent } from './_components/cookie-policy/cookie-policy.component';
import { WebchatTermsComponent } from './_components/webchat-terms/webchat-terms.component';
import { PaiaAndPopiaComponent } from './_components/paia-and-popia/paia-and-popia.component';

export const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'auctions',
    component: AuctionsComponent,
  },
  {
    path: 'calculator',
    component: CalculatorComponent,
  },
  {
    path: 'dealers',
    component: DealersComponent,
  },
  {
    path: 'faqs',
    component: FaqsComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'finance',
    component: FinanceComponent,
    children: [
      {
        path: 'edge',
        component: EdgeComponent
      },
      {
        path: 'instalment-sale',
        component: InstalmentSaleComponent
      }
    ],
  },
  
  {
    path: 'insurance',
    component: InsuranceComponent,
    children: [
      {
        path: 'care-cosmetic-repair-cover',
        component: CareCosmeticRepairCoverComponent
      },
      {
        path: 'comprehensive-cover',
        component: ComprehensiveCoverComponent
      },
      {
        path: 'extended-warranty-cover',
        component: ExtendedWarrantyCoverComponent
      },
      {
        path: 'top-up-cover',
        component: TopUpCoverComponent
      },
      {
        path: 'tyre-rim-cover',
        component: TyreRimCoverComponent
      }
    ],
  },

  {
    path: 'insurance/care-cosmetic-repair-cover',
    component: CareCosmeticRepairCoverComponent
  },
  {
    path: 'insurance/comprehensive-cover',
    component: ComprehensiveCoverComponent
  },
  {
    path: 'legal-notice',
    component: LegalNoticeComponent,
  },
  {
    path: 'privacy-statement',
    component: PrivacyStatementComponent,
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
  },
  {
    path: 'request-quote',
    component: RequestQuoteComponent,
  },
  {
    path: 'webchat-terms',
    component: WebchatTermsComponent,
  },

  {
    path: 'paia-and-popia',
    component: PaiaAndPopiaComponent
  },

  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
