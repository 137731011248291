<main>
    <section class="heroHeader pb-2 pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/care-cosmetic-repair.jpg" alt="ALPHERA Care & Cosmetic Repair Cover"
                class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Care & Cosmetic Repair Cover</h1>
                        <p>With specialised Care & Cosmetic Repair Cover from ALPHERA Financial Services, you can keep
                            your vehicle in showroom condition. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/insurance">Insurance</a></li>
                        <li><a routerLink="/insurance/care-cosmetic-repair-cover">ALPHERA Care & Cosmetic Repair
                                Cover</a></li>
                    </ul>
                </div>
                <!-- <h3>Keep your car picture perfect</h3>
                <p>Small scratches, dents, chips and abrasions are all too common. You could let these blemishes ruin
                    your car’s appearance, or choose this innovative cover that gives you access to experts in cosmetic
                    repairs.</p> -->
            </div>
        </div>
    </section>


    <section class="pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row">
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/cosmetic-exterior.jpg"
                                    alt="ALPHERA Care and Cosmetic Exterior Cover Plan" class="img-fluid">
                            </div>
                            <div class="teaserDescription">
                                <h3>ALPHERA Care and Cosmetic Exterior Cover Plan</h3>
                                <p>ALPHERA Care and Cosmetic Exterior Cover Plan offers the following benefits up to a
                                    maximum of R7 000.00 (VAT incl.).
                                </p>
                                <ul>
                                    <li>ALPHERA scratch repair. Covers scratches to the bodywork of the vehicle up to
                                        15cm in length.</li>
                                    <li>ALPHERA wheels and alloy rims repairs. Covers up to a maximum of R7 000.00 (VAT
                                        incl.) per 12 (twelve) month period of cover.</li>
                                    <li>ALPHERA windscreen and glass repairs. Windscreen chips and repairs to headlamps
                                        and related glass components, to the value of R7 000.00 (VAT incl.).</li>
                                    <li>ALPHERA tar removal. Unlimited restorations up to a maximum of R7 000.00 (VAT
                                        incl.).</li>
                                    <li>ALPHERA dent repair. Dents to the bodywork of the vehicle up to 15cm in
                                        diameter.</li>
                                    <li>ALPHERA hail damage repair. Covers up to a maximum of R7 000.00 (VAT incl.) per
                                        12 (twelve) month period of cover.</li>
                                    <li>ALPHERA chip repairs. Repairs damage not exceeding 1.5mm in diameter.</li>
                                </ul>

                                <a href="javascript:void(0);" (click)="goToProduct('Care and Cosmetic Repair Cover')"
                                    class="button button-g-blue">Request Quote</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/care-cosmetic-interior.jpg"
                                    alt="Top Up" class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>ALPHERA Care and Cosmetic Interior Cover Plan</h3>
                                <p>ALPHERA Care and Cosmetic Interior Cover Plan offer the following benefits up to a
                                    maximum of R3 000.00 (VAT incl.).</p>
                                <ul>
                                    <li>Centre console scratches, scuffs or rubber peeling repairs</li>
                                    <li>Door handle scratches or scuffs repair</li>
                                    <li>Door plastic kick panel & plastic panel door repairs</li>
                                    <li>Hand brake boot, restore if torn or ripped</li>
                                    <li>Centre armrest will be repaired</li>
                                    <li>Head rest damage will be repaired</li>
                                    <li>Front seat backboard and pocket repairs</li>
                                    <li>Interior boot carpet repairs</li>
                                    <li>Seat panel replace</li>
                                    <li>Seat stitch</li>
                                    <li>Steering wheel re-spray</li>
                                    <li>Rubber carpet inserts</li>
                                    <li>Gear knob repairs</li>
                                    <li>Sun visor damage repair</li>
                                </ul>
                                <a href="javascript:void(0);" (click)="goToProduct('Care and Cosmetic Repair Cover')"
                                    class="button button-g-blue">Request Quote</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/inside-out.jpg"
                                    alt="Top Up over R1,5m" class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>ALPHERA Care and Cosmetic Inside-out Cover Plan</h3>
                                <p>ALPHERA Care and Cosmetic Inside Out Cover Plan covers all of the Exterior Cover Plan
                                    and Interior Cover Plan benefits listed above at a reduced premium.</p>

                                <p><strong>Claims Procedure:</strong></p>
                                <ol>
                                    <li>Call the national number to register your claim on 0860 100 269.</li>
                                    <li>The claims department will request your policy number and all relevant
                                        information.</li>
                                    <li>The claims department will request a photograph of the damage.</li>
                                    <li>Send quotations and photograph to the claims department who will assess your
                                        claim.</li>
                                </ol>


                                <a href="javascript:void(0);" (click)="goToProduct('Care and Cosmetic Repair Cover')"
                                    class="button button-g-blue">Request Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="pTB downloadFilesSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Documents download</h3>
                <p>Enjoy quick access to your cover documents. Find all the product information you need in the
                    brochure. Existing customers can also download a claim form, online. </p>

                <div class="downloadFiles">
                    <a href="./assets/files/706749_ALPHERA_C-C_One_Pager.pdf" class="button button-ghost"
                        target="_blank">Download Brochure</a>
                    <a href="./assets/files/ALPHERA_Care-Cosmetics_Claim_form.pdf" class="button button-ghost"
                        target="_blank">Download Claim Form</a>
                    <a href="./assets/files/FPP10992_CCR_ALPHERA_Insurance_claims_procedure.pdf"
                        class="button button-ghost" target="_blank">Download Claims Procedure</a>
                    <a href="./assets/files/706749_ALPHERA_CCR_Assess.pdf" class="button button-ghost"
                        target="_blank">Download Repair Assessment Guide</a>
                </div>
            </div>
        </div>
    </section>

    <section class="bgPattern pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <app-insurance-teasers [insuranceTypeToChild]="'care-cosmetic-repair-cover'"></app-insurance-teasers>
            </div>
        </div>
    </section>

    <section class="bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Need a quote or support?</h3>
                <p>Whether it’s a custom quote for the insurance product you have your eye on, or you need a support
                    from our experienced insurance team, we are here to assist you on your journey. Please don’t
                    hesitate to get in touch for any questions, enquires or feedback. We are looking forward to hearing
                    from you.
                </p>

                <div class="col-md-6">
                    <app-care-cosmetic-repair [showBtn]="'Yes'"></app-care-cosmetic-repair>
                </div>
            </div>
        </div>
    </section>
</main>