<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/instalments-sale.jpg" alt="ALPHERA - Instalment Sale" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA - Instalment Sale</h1>
                        <p>Our flexible instalment sale plan allows you to drive away in your dream car. At the end of
                            your term, you decide if you want to keep the vehicle, sell it, or trade it in.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/finance">Finance</a></li>
                        <li><a routerLink="/finance/instalment-sale">Instalment Sale</a></li>
                    </ul>
                </div>
                <!-- <h3>Looking to pay off your vehicle with monthly instalments? </h3>
                <p>ALPHERA Financial Services gives you the freedom to pay off your dream vehicle with monthly
                    instalments that you can manage. From selecting your optional deposit and balloon to choosing your
                    repayment period and receiving a personalised interest rate - an ALPHERA Instalment Sale agreement
                    gives you access to vehicle finance on your terms. </p> -->
            </div>
        </div>
    </section>

    <section class="pTB detailSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Instalment Sale: 4 ways to customise your contract</h3>
                <ol>
                    <li>
                        <strong>Balloon versus no balloon</strong> - you can choose the option of reducing your monthly
                        payments with the addition of a balloon (payable at the end of your contract term) or skip it
                        and enjoy full ownership at the end of your journey.
                    </li>
                    <li>
                        <strong>Optional deposit</strong> - Trading in your current vehicle or just planning on putting
                        a deposit down on your new contract? You have the flexibility to choose a deposit that works for
                        you - starting from 0%.
                    </li>
                    <li>
                        <strong>Flexible payment term</strong> - Adjust your monthly payments by selecting the contract
                        term that works for you – from 12 to 72 months.
                    </li>
                    <li>
                        <strong>Fixed Rate option</strong> - You have the option of choosing a fixed interest rate on
                        your Instalment Sale contract. Enjoy added peace-of-mind with lower risk and a constant monthly
                        payment throughout your term.
                        <div class="mt-4">
                            <a href="https://app.bmwfinance.co.za/sf/ALPHERAOnlCreditApp/index.html"
                                class="button button-g-blue" target="_blank">Finance Application</a>
                        </div>
                    </li>
                </ol>

                <h3>The end of your Instalment Sale contract: All you need to know</h3>
                <p>Learn more about your ALPHERA Instalment Sale end-of-term options in our tutorial video.</p>
                <p>With ALPHERA Instalment Sale, you have the following options available to you: you can either trade
                    in your vehicle for a newer model or take ownership by paying the final payment, or you can
                    refinance the balloon (if applicable).
                </p>

                <div class="ratio ratio-16x9 videoWrapper">
                    <iframe src="https://forms.bmw.co.za/ALPHERA%20-Instalment-Sale-EOT.mp4" title="YouTube video"
                        allowfullscreen></iframe>
                </div>

                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5">
                        <img src="assets/img/keep-vehicle.jpg" alt="ALPHERA Finance Keep your vehicle"
                            class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pS">
                            <h2>1. Keep your vehicle</h2>
                            <p>Once you have paid your agreement in full, you become the proud owner of your vehicle. If
                                you decide to keep your vehicle and your Instalment Sale includes a balloon, you can
                                refinance the outstanding amount with our help or settle the lump sum and take
                                ownership. If your Instalment Sale agreement has no balloon, you become the owner of
                                your vehicle once your final payment has been made.</p>
                        </div>
                    </div>
                </div>


                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5 order-md-1">
                        <img src="assets/img/trade-in.jpg" alt="ALPHERA Finance Trade-In your vehicle"
                            class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pE">
                            <h2>2. Trade-In your vehicle</h2>
                            <p>If you no longer want to keep your vehicle, you can trade it. If you would like to trade
                                in your vehicle for a newer one, you can obtain the outstanding settlement figure for
                                your finance agreement via our MyAccount Portal.</p>

                            <a href="https://alpherafinance.myaccount.co.za/login" target="_blank"
                                class="button button-g-blue">Go to MyAccount </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section class="pTB bgPattern bottomSec">
        <div class="container">
            <div class="containerWrapper">
                <h2>Looking for something a little different? </h2>
                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5">
                        <img src="assets/img/edge.jpg" alt="ALPHERA Finance Edge" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pS">
                            <h2>Edge</h2>
                            <p><strong>The best of both</strong></p>
                            <p>Edge is an innovative 50:50 payment plan which gives you the benefit of not having to
                                deplete your savings or withdraw from your bond to purchase the car of your dreams in
                                cash.</p>

                            <a routerLink="/finance/edge" class="button button-g-blue">Explore Edge</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>