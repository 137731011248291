import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import primedata from "../../../assets/primerate.json";
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.less']
})
export class CalculatorComponent implements OnInit {
  repaymentPeriod: number = 12;
  balloonPercentage: number = 0;
  totalBalloonValue: number = 0;
  totalRepaymentsValue: number = 0;
  price: number = 0;
  deposit: number = 0;
  interest: number = 1;
  prime: number = 0;
  submitted: boolean = false;
  primeValueSelected: number = 0;

  percentValue: number = 0;
  rangeValue: number = 0;


  calcForm = new FormGroup({
    periodSelector: new FormControl(this.repaymentPeriod, [Validators.required]),
    balloonSelector: new FormControl(this.balloonPercentage, [Validators.required]),
    totalDeposit: new FormControl(this.deposit, [Validators.required, Validators.pattern("^[0-9]*$")]),
    purchasePrice: new FormControl(this.price, [Validators.required, Validators.pattern("^[0-9]*$")]),
    eMail: new FormControl('', [Validators.required, Validators.email]),
    interestRate: new FormControl(this.interest, [Validators.required]),
  });
  get f() {
    return this.calcForm.controls;
  }
  constructor(private helperSVC: HelperService) { }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Calculator | BMW Aphera'`);
  }

  updateTooltip(event: any): void {
    const inputValue = event.target.value;
    const tooltipElement = document.querySelector('input[type=range]');


if (tooltipElement) {
      tooltipElement.setAttribute('data-bs-title', `${inputValue}%`);
      tooltipElement.setAttribute('title', `${inputValue}%`);
      this.percentValue = inputValue;
    }

  }

  calculatePercent(){
    return (this.rangeValue / 40) * 100;
  }

  calculateIndicator(){
    const leftPercent = this.calculatePercent();
    if(leftPercent==100){
      if(window.innerWidth < 576){
        return 95
      }
      return 98
    }
   if(leftPercent==0){
      return leftPercent
    }

    return leftPercent - 1
  }

  doCalc() {
    var Cap = 0, Dep = 0, InitFee = 1207.5, PD = 0, RV = 0, Term = 0, Rate = 0, Int = 0, Pmt = 0, Servicefee = primedata.serviceFee;
    var A = ((Rate / 100) / 12);
    var B = 0;
    var C = 0;
    if (this.f["purchasePrice"].value != null) {
      this.price = this.f["purchasePrice"].value;
    }
    Cap = this.price;
    if (isNaN(Cap))
      Cap = 0;

    if (this.f["totalDeposit"].value != null) {
      this.deposit = this.f["totalDeposit"].value;
    }

    Dep = this.deposit;

    if (isNaN(Dep))
      Dep = 0;

    PD = Cap - Dep + InitFee;
    if (this.f["totalDeposit"].value != null) {
      this.deposit = this.f["totalDeposit"].value;
    }

    RV = this.balloonPercentage;
    RV = ((RV / 100) * Cap);
 
    Term = this.repaymentPeriod;

    if (isNaN(Term))
      Term = 60;
    Rate = this.primeValueSelected;
    if (isNaN(Rate))
      Rate = 0;
    // Int = ipmt(((Rate / 100) / 12), 1, Term, PD, RV * -1, 0) * -1; <-- not used


    A = ((Rate / 100) / 12);
    //alert('Rate '+ getInterestRate() + ' / '+ A)

    if (RV != 0) {
      B = Term - 1;
    } else {
      B = Term;
    }
    C = -(PD + (PD * A));
    this.totalRepaymentsValue = this.pmt(A, B, C, RV, 1) + primedata.serviceFee;
    this.totalBalloonValue = RV;
  }

  pmt(rate: number, nper: number, pv: number, fv: number, due: any) {
    var start_pv = 0;
    var end_pv = 0;
    var start_fv = 0;
    var end_fv = 0;
    var t; // Time period
    var df_pv = 0.00; // Discount factor to apply against present value
    var df_fv = 0.00; // Discount factor to apply against future value

    switch (due) {
      case 0://each payment is made at the end
        start_pv = 1;
        end_pv = nper;
        start_fv = 0;
        end_fv = nper - 1;
        break;
      case 1://each payment is made at the beginning
        start_pv = 0;
        end_pv = nper - 1;
        start_fv = 1;
        end_fv = nper;
        break;
    }

    /* Calculate the discount factor that gives you payment from present value  */
    for (t = start_pv; t <= end_pv; t++) {
      var curr = Math.pow(1 + rate, t * 1.00);
      df_pv += 1 / curr;
    }
    /*
     * Calculate the discount factor that gives you payment from future
     * value
     */
    for (t = start_fv; t <= end_fv; t++) {
      curr = Math.pow((1 + rate), t * 1.00);
      df_fv += curr;
    }
    let returnVal =  (pv / df_pv + fv / df_fv) * -1;
    

    return returnVal;
  }
  //listeners
  periodChanged(e: any) {
    this.repaymentPeriod = e.target.value;
    this.doCalc();
  }
  balloonChanged(e: any) {
    this.balloonPercentage = e.target.value;
    this.doCalc();
  }
  priceChanged(e: any) {
    this.price = e.target.value;
    if (e.target.value < 50000) {
      this.price = 50000;
    }
    this.doCalc();

  }
  depositChanged(e: any) {
    this.deposit = e.target.value;
    this.doCalc();
  }
  interestChanged(e: any) {

    switch (e.target.value) {
      case "0":

        this.primeValueSelected = primedata.primerate;
        break;
      case "1":

        this.primeValueSelected = primedata.primerate + 1;
        break;
      case "2":
        this.primeValueSelected = primedata.primerate + 2;
        break;
      case "3":
        this.primeValueSelected = primedata.primerate + 3;
        break;
      case "4":
        this.primeValueSelected = primedata.primerate + 4;
        break;

    }
    this.doCalc();
  }
}
