import {HttpBackend, HttpClient, HttpHeaders, HttpXsrfTokenExtractor} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient) { }

  sendContacts(formData: any): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': '*/*'});
    var configs = { headers: headers };
    const urlEncodedFormData = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&');
    return this.http.post<any>("assets/asp/contact.asp", urlEncodedFormData, configs)
      .pipe(map(data => data as any));
  }

  
  contactsDealer(formData: any): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': '*/*'});
    var configs = { headers: headers };
    const urlEncodedFormData = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&');
    return this.http.post<any>("assets/asp/dealer-process.asp", urlEncodedFormData, configs)
      .pipe(map(data => data as any));
  }
}
