import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.less']
})
export class FinanceComponent implements OnInit {
  constructor(
    private router: Router,
    private helperSVC: HelperService 
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.checkUrl('/finance')
      }
      if (event instanceof NavigationEnd) {
        this.checkUrl('/finance')
      }
    });
  }
  parent: boolean = false;

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Finance | BMW Aphera'`);
    this.checkUrl('/finance')
  }


  checkUrl(reqPath: string){
    const pathName = window.location.pathname;
    let combinedPath = reqPath+'/';
    // if(pathName === reqPath){
    //   this.parent = true;
    // }
    if(pathName.indexOf(reqPath) != -1 && pathName.indexOf(combinedPath) === -1){
      this.parent = true;
    }
    else{
      this.parent = false;
    }
  }
}
