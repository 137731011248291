import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-care-cosmetic-repair-cover',
  templateUrl: './care-cosmetic-repair-cover.component.html',
  styleUrls: ['./care-cosmetic-repair-cover.component.less']
})
export class CareCosmeticRepairCoverComponent implements OnInit {

  constructor(
    private router: Router,
    private helperSVC: HelperService
  ) { }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Insurance-Care-Cosmetic-Repair | BMW Aphera'`);
  }

  goToProduct(product:string){
    // this.quoteSvc.product = product;
    sessionStorage.setItem("_ins_prod", product);
    this.router.navigate(['/request-quote']);
  }
}
