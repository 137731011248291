<main>
    <section class="heroHeader pb-2 pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/home.jpg" alt="" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Tailored Finance and Insurance solutions</h1>
                        <p>ALPHERA Financial Services was founded by BMW Group Financial Services in 2001 - with the aim
                            of making our expert knowledge, experience, and passion for mobility available to buyers of
                            other vehicle brands – whether cars or motorcycles.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="bgPattern introSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row  justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5 order-md-1">
                        <img src="assets/img/car-finance.jpg" alt="Car finance built on quality" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pE">
                            <h2>Car finance built on quality, clarity and choice</h2>
                            <p>We provide vehicle finance and insurance solutions focussed on value, simplicity, freedom
                                and
                                flexibility. Whether you’re in the market for a new or used vehicle, let us make owning
                                your
                                dream vehicle of any make or model, a reality. </p>
                            <p>Tailored to you and your lifestyle, ALPHERA Financial Services is your trusted finance
                                partner.
                                Enjoy the convenience of applying for finance, online, and having secure online access
                                to your
                                account throughout your finance journey.</p>
                            <p>With over 20 years in the industry and a nationwide network of nearly 200 official
                                ALPHERA
                                partners, we are ready to offer you a bespoke experience with our professional approach
                                and
                                outstanding service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="bgPattern productHighlight">
        <div class="container">
            <div class="containerWrapper">
                <h2>Highlights of our product range</h2>
                <div class="row">
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/finance.jpg" alt="" class="img-fluid">
                            </div>
                            <div class="teaserDescription">
                                <h3>ALPHERA Finance Products</h3>
                                <p>ALPHERA Financial Services is a leading provider of multi-brand vehicle finance
                                    products. Innovative finance solutions for new and quality pre-owned vehicles enable
                                    our partners to offer finance terms tailored to you.</p>
                                <a routerLink="/finance" class="simpleLink">Explore finance </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/insurance-cover.jpg" alt=""
                                    class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>ALPHERA Insurance Cover</h3>
                                <p>Now, ALPHERA customers who already enjoy our efficiency and quality of service in
                                    motor vehicle finance can also enjoy cover that goes one step further with our
                                    extensive range of insurance solutions.</p>
                                <a routerLink="/insurance" class="simpleLink">Explore insurance</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/calculator.jpg" alt=""
                                    class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>ALPHERA Finance Calculator </h3>
                                    <p>The finance calculator helps you estimate the monthly repayments on your dream car or
                                        motorcycle. Using the sliders, you can select the repayment period and (optional)
                                        balloon amount that works for you. </p>
                                        <a routerLink="/calculator" class="simpleLink">Go to calculator </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="sliderSec">
        <swiper [config]="config" class="homeSwiper" #mySwiper>
            <ng-template swiperSlide>
                <div class=" sliderWrapper">
                    <div class="slideItem">
                        <img src="./assets/img/slide/become-partner.jpg" alt="Alphera become a partner"
                            class="img-fluid slideImg" />
                        <div class="slideCopy">
                            <h3>Become a partner </h3>
                            <p>We know how important immediate service and a quick response is in your daily
                                operations. We will support your regular business operations and finance your
                                inventory of vehicles. As a trusted partner, we can also offer finance for your
                                dealership and your inventory. The ALPHERA Commercial Finance team are there to
                                assist you.</p>
                            <a routerLink="/dealers" class="simpleLink white">Learn more </a>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template swiperSlide>
                <div class=" sliderWrapper">
                    <div class="slideItem">
                        <img src="./assets/img/slide/car-auctions.jpg" alt="Alphera calculator"
                            class="img-fluid slideImg" />
                        <div class="slideCopy">
                            <h3>ALPHERA Used Car Auctions</h3>
                            <p>Public auctions of repossessed vehicles take place at various venues. These auctions
                                are managed by independent auctioneers. Vehicles on auction can be purchased by
                                individual customers, ALPHERA dealers and other interested parties.</p>
                                <a routerLink="/auctions" class="simpleLink white">Used car auctions</a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>

        <div class="containerWrapper swiperNav">
            <div class="swiper-button-prev" (click)="swiperPrev()" [class.swiper-button-disabled]="hasPreviousSlide">
            </div>
            <div class="swiper-button-next" (click)="swiperNext()" [class.swiper-button-disabled]="hasNextSlide"></div>
        </div>
    </section>
</main>