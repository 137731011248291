<main>
    <section class="heroHeader pb-2 pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/contacts.jpg" alt="Contact ALPHERA Financial Services" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Contact ALPHERA Financial Services</h1>
                        <p>If you’d find it more helpful to speak to us directly, you can use the contact details below.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/contact-us">Contact Us</a></li>
                    </ul>
                </div>
                <h3>Stay one step ahead with ALPHERA MyAccount </h3>
                <p>Did you know you can manage your finance agreement online with ALPHERA MyAccount? If you’d like to
                    obtain a settlement quote, update your personal details, change your payment date, or arrange a
                    vehicle collection you can use the ALPHERA MyAccount platform. <a href="https://alpherafinance.myaccount.co.za/login" target="_blank" class="simple-link">Click here</a> to log in or register now.
                </p>

            </div>
        </div>
    </section>


    <section class="pTB faqsSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row">
                    <div class="col-md-6">
                        <app-comprehensive></app-comprehensive>
                    </div>
                    <div class="col-md-6">
                        <app-top-up-tyre-rim-extended></app-top-up-tyre-rim-extended>
                    </div>
                    <div class="col-md-6">
                        <app-care-cosmetic-repair></app-care-cosmetic-repair>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="pTB contactSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Contact Us</h3>
                <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-md-12 col-lg-4 formGroup">
                            <label class="form-label">Title*</label>
                            <select name="title" formControlName="title"
                                [ngClass]="{ 'error': submitted && f['title'].errors }" class="form-control">
                                <option value="" class="" selected="selected">Please select</option>
                                <option value="Admiral" selected="selected">Admiral</option>
                                <option value="Advocate">Advocate</option>
                                <option value="Ambassador">Ambassador</option>
                                <option value="Attorney">Attorney</option>
                                <option value="Brig">Brig</option>
                                <option value="Brig Gen">Brig Gen</option>
                                <option value="Capt">Capt</option>
                                <option value="Chief">Chief</option>
                                <option value="Colonel">Colonel</option>
                                <option value="Commandant">Commandant</option>
                                <option value="Commander">Commander</option>
                                <option value="Commissioner">Commissioner</option>
                                <option value="Company">Company</option>
                                <option value="Consul">Consul</option>
                                <option value="Councillor">Councillor</option>
                                <option value="Dir">Dir</option>
                                <option value="Dr">Dr</option>
                                <option value="General">General</option>
                                <option value="Governor">Governor</option>
                                <option value="His Majesty">His Majesty</option>
                                <option value="Hon">Hon</option>
                                <option value="Judge">Judge</option>
                                <option value="Justice">Justice</option>
                                <option value="Lady">Lady</option>
                                <option value="Lieut">Lieut</option>
                                <option value="Lieut Cdr">Lieut Cdr</option>
                                <option value="Lieut Col">Lieut Col</option>
                                <option value="Lieut Gen">Lieut Gen</option>
                                <option value="Lord">Lord</option>
                                <option value="Maj Gen">Maj Gen</option>
                                <option value="Major">Major</option>
                                <option value="Minister">Minister</option>
                                <option value="Miss">Miss</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Pastor">Pastor</option>
                                <option value="President">President</option>
                                <option value="Prince">Prince</option>
                                <option value="Princess">Princess</option>
                                <option value="Prof">Prof</option>
                                <option value="Rev">Rev</option>
                                <option value="Sir">Sir</option>
                                <option value="The Hon">The Hon</option>
                                <option value="The Hon Minister">The Hon Minister</option>
                                <option value="Your Excellency">Your Excellency</option>
                            </select>
                            <span *ngIf="submitted && f['title'].errors" class="help-block form-error">
                                <span *ngIf="f['title'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 col-lg-4 formGroup">
                            <label class="form-label">First Name*</label>
                            <input type="text" placeholder="" name="firstName" formControlName="firstName"
                                [ngClass]="{ 'error': submitted && f['firstName'].errors }" required>
                            <span *ngIf="submitted && f['firstName'].errors" class="help-block form-error">
                                <span *ngIf="f['firstName'].errors['required']">This is a required field</span>
                                <span *ngIf="f['firstName'].errors['pattern']">First name is invalid</span>
                            </span>
                        </div>
                        <div class="col-md-6 col-lg-4 formGroup">
                            <label class="form-label">Surname*</label>
                            <input type="text" placeholder="" name="surname" formControlName="surname"
                                [ngClass]="{ 'error': submitted && f['surname'].errors }">
                            <span *ngIf="submitted && f['surname'].errors" class="help-block form-error">
                                <span *ngIf="f['surname'].errors['required']">This is a required field</span>
                                <span *ngIf="f['surname'].errors['pattern']">Surname is invalid</span>
                            </span>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-6 col-lg-4 formGroup" [class.col-lg-4]="!isHidden && selecttedComms !== ''">
                            <label class="form-label">Contact Preferences*</label>
                            <select name="prefTelephoneType" (change)="toggleVisibility($event)"
                                formControlName="prefTelephoneType"
                                [ngClass]="{ 'error': submitted && f['prefTelephoneType'].errors }">
                                <option value="">Please Select</option>
                                <option value="Cellphone">Cellphone</option>
                                <option value="Home telephone">Home telephone</option>
                                <option value="Work telephone">Work telephone</option>
                            </select>
                            <span *ngIf="submitted && f['prefTelephoneType'].errors" class="help-block form-error">
                                <span *ngIf="f['prefTelephoneType'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup"
                            [class.col-lg-4]="!isHidden && selecttedComms === 'Home telephone'"
                            *ngIf="!isHidden && selecttedComms === 'Home telephone'">
                            <label class="form-label">Home Telephone*</label>
                            <input type="text" placeholder="" name="homeTelephone" formControlName="homeTelephone"
                                [ngClass]="{ 'error': submitted && f['homeTelephone'].errors }">
                            <span *ngIf="submitted && f['homeTelephone'].errors" class="help-block form-error">
                                <span *ngIf="f['homeTelephone'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['homeTelephone'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup"
                            [class.col-lg-4]="!isHidden && selecttedComms === 'Work telephone'"
                            *ngIf="!isHidden && selecttedComms === 'Work telephone'">
                            <label class="form-label">Work Telephone*</label>
                            <input type="text" placeholder="" name="workTelephone" formControlName="workTelephone"
                                [ngClass]="{ 'error': submitted && f['workTelephone'].errors }">
                            <span *ngIf="submitted && f['workTelephone'].errors" class="help-block form-error">
                                <span *ngIf="f['workTelephone'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['workTelephone'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup" [class.col-lg-4]="!isHidden && selecttedComms === 'Cellphone'"
                            *ngIf="!isHidden && selecttedComms === 'Cellphone'">
                            <label class="form-label">Cellphone*</label>
                            <input type="text" placeholder="" name="cellphone" formControlName="cellphone"
                                [ngClass]="{ 'error': submitted && f['cellphone'].errors }">
                            <span *ngIf="submitted && f['cellphone'].errors" class="help-block form-error">
                                <span *ngIf="f['cellphone'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['cellphone'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup" [class.col-lg-4]="!isHidden && selecttedComms !== ''">
                            <label class="form-label">Email*</label>
                            <input type="email" placeholder="" name="eMail" formControlName="eMail" email
                                [ngClass]="{ 'error': submitted && f['eMail'].errors }">
                            <span *ngIf="submitted && f['eMail'].errors" class="help-block form-error">
                                <span *ngIf="f['eMail'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['eMail'].errors['email']">E-mail must be a valid email
                                    address</span>
                            </span>
                        </div>

                        <div class="col-md-12 formGroup">
                            <label class="form-label">Deal/Policy/Ref. Number (if applicable)</label>
                            <input type="text" placeholder="" name="policyNumber" formControlName="policyNumber" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 formGroup">

                            <label class="form-label">Subject*</label>
                            <select name="subject" (change)="toggleInsuranceVisibility($event)"
                                formControlName="subject" [ngClass]="{ 'error': submitted && f['subject'].errors }"
                                class="form-control">
                                <option value="">Please select</option>
                                <option value="Finance">Finance</option>
                                <option value="Insurance">Insurance</option>
                                <option value="MyAccount">MyAccount</option>
                                <option value="Credit Application">Credit Application</option>
                                <option value="Complaint">Complaint</option>
                                <option value="Other">Other</option>
                            </select>
                            <span *ngIf="submitted && f['subject'].errors" class="help-block form-error">
                                <span *ngIf="f['subject'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup" *ngIf="showInsuranceField && selectedSubject === 'Insurance'">
                            <label class="form-label">Insurance product*</label>
                            <select name="insuranceOffers" formControlName="insuranceOffers"
                                [ngClass]="{ 'error': submitted && f['insuranceOffers'].errors }" class="form-control">
                                <option value="">Please select</option>
                                <option value="Alphera Comprehensive Cover">Alphera Comprehensive Cover</option>
                                <option value="Alphera Top Up Cover">Alphera Top Up Cover</option>
                                <option value="Alphera Care and Cosmetic Repair Cover">Alphera Care and Cosmetic Repair
                                    Cover</option>
                                <option value="Alphera Tyre Cover">Alphera Tyre Cover</option>
                                <option value="Alphera Extended Warranty Cover">Alphera Extended Warranty Cover</option>
                            </select>
                            <span *ngIf="submitted && f['insuranceOffers'].errors" class="help-block form-error">
                                <span *ngIf="f['insuranceOffers'].errors['required']">This is a required field</span>
                            </span>
                        </div>
                        <div class="col-md-6 formGroup" *ngIf="showInsuranceField && selectedSubject === 'Insurance'">
                            <label class="form-label">Enquiry type*</label>
                            <select name="insuranceOffersReason" formControlName="insuranceOffersReason"
                                [ngClass]="{ 'error': submitted && f['insuranceOffersReason'].errors }"
                                class="form-control">
                                <option value="">Please select</option>
                                <option value="Request a Quote">Request a Quote</option>
                                <option value="Amendments to Active Policies">Amendments to Active Policies</option>
                                <option label="Claims" value="Claims">Claims</option>
                            </select>
                            <span *ngIf="submitted && f['insuranceOffersReason'].errors" class="help-block form-error">
                                <span *ngIf="f['insuranceOffersReason'].errors['required']">This is a required
                                    field</span>
                            </span>
                        </div>




                        <div class="col-md-6 col-lg-4 formGroup"
                            *ngIf="showInsuranceField && selectedSubject === 'Complaint'">
                            <label class="form-label">ID Number*</label>
                            <input type="text" placeholder="" name="idNumber" formControlName="idNumber"
                                [ngClass]="{ 'error': submitted && f['idNumber'].errors }">
                            <span *ngIf="submitted && f['idNumber'].errors" class="help-block form-error">
                                <span *ngIf="f['idNumber'].errors['required']">This is a required field</span>
                                <span *ngIf="f['idNumber'].errors['pattern']">Please enter valid ID Number</span>
                            </span>
                        </div>

                        <div class="col-md-6 col-lg-4 formGroup"
                            *ngIf="showInsuranceField && selectedSubject === 'Complaint'">
                            <label class="form-label">Person contacted previously*</label>
                            <input type="text" placeholder="" name="contactperson" formControlName="contactperson"
                                [ngClass]="{ 'error': submitted && f['contactperson'].errors }">
                            <span *ngIf="submitted && f['contactperson'].errors" class="help-block form-error">
                                <span *ngIf="f['contactperson'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-12 col-lg-4 formGroup"
                            *ngIf="showInsuranceField && selectedSubject === 'Complaint'">
                            <label class="form-label">Date of contact*</label>
                            <input type="text" placeholder="" name="date" formControlName="date"
                                [ngClass]="{ 'error': submitted && f['date'].errors }">
                            <span *ngIf="submitted && f['date'].errors" class="help-block form-error">
                                <span *ngIf="f['date'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-12 col-lg-6 formGroup"
                            *ngIf="showInsuranceField && selectedSubject === 'Complaint'">
                            <label class="form-label">Complaint details*</label>
                            <textarea name="complaintdetails" formControlName="complaintdetails"
                                [ngClass]="{ 'error': submitted && f['complaintdetails'].errors }" class="form-control"
                                rows="3">
                            </textarea>
                            <span *ngIf="submitted && f['complaintdetails'].errors" class="help-block form-error">
                                <span *ngIf="f['complaintdetails'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-12 col-lg-6 formGroup"
                            *ngIf="showInsuranceField && selectedSubject === 'Complaint'">
                            <label class="form-label">How would you like your complaint resolved?*</label>
                            <textarea name="complaintresolution" formControlName="complaintresolution"
                                [ngClass]="{ 'error': submitted && f['complaintresolution'].errors }"
                                class="form-control" rows="3">
                            </textarea>
                            <span *ngIf="submitted && f['complaintresolution'].errors" class="help-block form-error">
                                <span *ngIf="f['complaintresolution'].errors['required']">This is a required
                                    field</span>
                            </span>
                        </div>

                        <div class="col-md-12 formGroup" *ngIf="selectedSubject !== 'Complaint'">
                            <label class="form-label">Enquiry*</label>
                            <textarea name="enquiry" formControlName="enquiry"
                                [ngClass]="{ 'error': submitted && f['enquiry'].errors }" class="form-control" rows="3">
                            </textarea>
                            <span *ngIf="submitted && f['enquiry'].errors" class="help-block form-error">
                                <span *ngIf="f['enquiry'].errors['required']">This is a required field</span>
                            </span>
                        </div>
                    </div>
                    <div class="alert notification" role="alert" *ngIf="alertResponse !='' "
                        [ngClass]="alertResponse==='success' ? 'alert-success':'alert-danger'">
                        <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button
                                type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
                    </div>
                    <button type="submit" class="button button-g-blue" *ngIf="alertResponse===''">Submit
                        Request</button>
                </form>
            </div>
        </div>
    </section>
</main>