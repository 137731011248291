
<div class="cardBox bgWhite contact">
    <h4>Comprehensive Insurance</h4>
    <h5>For all general enquiries or questions about your cover</h5>
    <ul>
        <li><i class="icon icon--phone"></i> <span><a href="tel:+27860100269">0860 100 269</a> (8.00am to 7.00pm Monday - Friday; 9.00am to 5.00pm Saturday)</span> </li>
        <li><i class="icon icon--email"></i> <span>Request a Quote: <a href="mailto:cccbmwnew@psg.co.za" target="_blank">cccbmwnew@psg.co.za</a></span></li>
        <li><i class="icon icon--email"></i> <span>Amendments to Active Policies: <a href="mailto:cccbmwnew@psg.co.za" target="_blank">cccbmwnew@psg.co.za</a></span></li>
        <li><i class="icon icon--email"></i> <span>Claims: <a href="mailto:NewClaims@psg.co.za" target="_blank">NewClaims@psg.co.za</a></span></li>
    </ul>
    <a (click)="goToProduct('Comprehensive Cover')" class="button button-g-blue" *ngIf="showBtn==='Yes'">Complete Contact Form</a>
</div>