<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/dealers.jpg" alt="ALPHERA Dealers" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Dealers</h1>
                        <p>Regardless of the vehicle that your customer dreams of, our flexible finance and insurance
                            products will make financing it possible. We offer a wide range of finance and insurance
                            products tailored to the needs of vehicle and motorcycle owners.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/dealers">Dealers</a></li>
                    </ul>
                </div>
                <h3>Become a Partner</h3>
                <p>Regardless of the vehicle that your customer dreams of, our flexible finance and insurance products
                    will make financing it possible. We offer a wide range of finance and insurance products tailored to
                    the needs of vehicle and motorcycle owners. As part of the BMW Group, we bring experience and
                    expertise to vehicle finance and insurance, offering you the opportunity to get the professional
                    edge.
                </p>
                <p>
                    We know how important immediate service and a quick response is in your daily operations. We will
                    support your regular business operations and finance your inventory of vehicles. As a trusted
                    partner, we can also offer finance for your dealership and your inventory. The ALPHERA Commercial
                    Finance team are there to assist you.</p>
            </div>
        </div>
    </section>


    <section class="bgPattern bottomTeaserSec">
        <div class="container">
            <div class="row containerWrapper justify-content-between align-items-center teaserHorizontal">
                <div class="col-md-5">
                    <img src="assets/img/dealers.jpg" alt="Join the perfect team" class="img-fluid">
                </div>
                <div class="col-md-7">
                    <div class="teaserCopy pS">
                        <h2>Join the perfect team</h2>
                        <p>With ALPHERA Financial Services, you have a reputable and experienced business partner on
                            your side that lets you put the keys into the hands of your customers. We listen to the
                            needs and requests of our ALPHERA partners, learn from them and learn with them. This
                            knowledge enables us to give you a clear lead on the competition.
                        </p>
                        <p>
                            To join the ALPHERA Financial Services Partner network, simply complete the Contact form
                            below and a member of the ALPHERA Team will get back to you.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="pTB contactSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Contact Us</h3>
                <p>Interested in joining the ALPHERA Dealer network? Contact us today and one of our ALPHERA team
                    members will be in touch.</p>
                <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-md-12 col-lg-4 formGroup">
                            <label class="form-label">Title*</label>
                            <select name="title" formControlName="title"
                                [ngClass]="{ 'error': submitted && f['title'].errors }" class="form-control">
                                <option value="" class="" selected="selected">Please select</option>
                                <option value="Admiral" selected="selected">Admiral</option>
                                <option value="Advocate">Advocate</option>
                                <option value="Ambassador">Ambassador</option>
                                <option value="Attorney">Attorney</option>
                                <option value="Brig">Brig</option>
                                <option value="Brig Gen">Brig Gen</option>
                                <option value="Capt">Capt</option>
                                <option value="Chief">Chief</option>
                                <option value="Colonel">Colonel</option>
                                <option value="Commandant">Commandant</option>
                                <option value="Commander">Commander</option>
                                <option value="Commissioner">Commissioner</option>
                                <option value="Company">Company</option>
                                <option value="Consul">Consul</option>
                                <option value="Councillor">Councillor</option>
                                <option value="Dir">Dir</option>
                                <option value="Dr">Dr</option>
                                <option value="General">General</option>
                                <option value="Governor">Governor</option>
                                <option value="His Majesty">His Majesty</option>
                                <option value="Hon">Hon</option>
                                <option value="Judge">Judge</option>
                                <option value="Justice">Justice</option>
                                <option value="Lady">Lady</option>
                                <option value="Lieut">Lieut</option>
                                <option value="Lieut Cdr">Lieut Cdr</option>
                                <option value="Lieut Col">Lieut Col</option>
                                <option value="Lieut Gen">Lieut Gen</option>
                                <option value="Lord">Lord</option>
                                <option value="Maj Gen">Maj Gen</option>
                                <option value="Major">Major</option>
                                <option value="Minister">Minister</option>
                                <option value="Miss">Miss</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Pastor">Pastor</option>
                                <option value="President">President</option>
                                <option value="Prince">Prince</option>
                                <option value="Princess">Princess</option>
                                <option value="Prof">Prof</option>
                                <option value="Rev">Rev</option>
                                <option value="Sir">Sir</option>
                                <option value="The Hon">The Hon</option>
                                <option value="The Hon Minister">The Hon Minister</option>
                                <option value="Your Excellency">Your Excellency</option>
                            </select>
                            <span *ngIf="submitted && f['title'].errors" class="help-block form-error">
                                <span *ngIf="f['title'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 col-lg-4 formGroup">
                            <label class="form-label">Name*</label>
                            <input type="text" placeholder="" name="firstName" formControlName="firstName"
                                [ngClass]="{ 'error': submitted && f['firstName'].errors }" required>
                            <span *ngIf="submitted && f['firstName'].errors" class="help-block form-error">
                                <span *ngIf="f['firstName'].errors['required']">This is a required field</span>
                                <span *ngIf="f['firstName'].errors['pattern']">First name is invalid</span>
                            </span>
                        </div>
                        <div class="col-md-6 col-lg-4 formGroup">
                            <label class="form-label">Surname*</label>
                            <input type="text" placeholder="" name="surname" formControlName="surname"
                                [ngClass]="{ 'error': submitted && f['surname'].errors }">
                            <span *ngIf="submitted && f['surname'].errors" class="help-block form-error">
                                <span *ngIf="f['surname'].errors['required']">This is a required field</span>
                                <span *ngIf="f['surname'].errors['pattern']">Surname is invalid</span>
                            </span>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-6 col-lg-4 formGroup" [class.col-lg-4]="!isHidden && selecttedComms !== ''">
                            <label class="form-label">Contact Preferences*</label>
                            <select name="prefTelephoneType" (change)="toggleVisibility($event)"
                                formControlName="prefTelephoneType"
                                [ngClass]="{ 'error': submitted && f['prefTelephoneType'].errors }">
                                <option value="">Please Select</option>
                                <option value="Cellphone">Cellphone</option>
                                <option value="Home telephone">Home telephone</option>
                                <option value="Work telephone">Work telephone</option>
                            </select>
                            <span *ngIf="submitted && f['prefTelephoneType'].errors" class="help-block form-error">
                                <span *ngIf="f['prefTelephoneType'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup"
                            [class.col-lg-4]="!isHidden && selecttedComms === 'Home telephone'"
                            *ngIf="!isHidden && selecttedComms === 'Home telephone'">
                            <label class="form-label">Home Telephone*</label>
                            <input type="text" placeholder="" name="homeTelephone" formControlName="homeTelephone"
                                [ngClass]="{ 'error': submitted && f['homeTelephone'].errors }">
                            <span *ngIf="submitted && f['homeTelephone'].errors" class="help-block form-error">
                                <span *ngIf="f['homeTelephone'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['homeTelephone'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup"
                            [class.col-lg-4]="!isHidden && selecttedComms === 'Work telephone'"
                            *ngIf="!isHidden && selecttedComms === 'Work telephone'">
                            <label class="form-label">Work Telephone*</label>
                            <input type="text" placeholder="" name="workTelephone" formControlName="workTelephone"
                                [ngClass]="{ 'error': submitted && f['workTelephone'].errors }">
                            <span *ngIf="submitted && f['workTelephone'].errors" class="help-block form-error">
                                <span *ngIf="f['workTelephone'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['workTelephone'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="col-md-6 formGroup" [class.col-lg-4]="!isHidden && selecttedComms === 'Cellphone'"
                            *ngIf="!isHidden && selecttedComms === 'Cellphone'">
                            <label class="form-label">Cellphone*</label>
                            <input type="text" placeholder="" name="cellphone" formControlName="cellphone"
                                [ngClass]="{ 'error': submitted && f['cellphone'].errors }">
                            <span *ngIf="submitted && f['cellphone'].errors" class="help-block form-error">
                                <span *ngIf="f['cellphone'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['cellphone'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="formGroup" [class.col-lg-4]="!isHidden && selecttedComms !== ''" [ngClass]="selecttedComms !== ''?'col-md-12':'col-md-6'">
                            <label class="form-label">Email*</label>
                            <input type="email" placeholder="" name="eMail" formControlName="eMail" email
                                [ngClass]="{ 'error': submitted && f['eMail'].errors }">
                            <span *ngIf="submitted && f['eMail'].errors" class="help-block form-error">
                                <span *ngIf="f['eMail'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['eMail'].errors['email']">E-mail must be a valid email
                                    address</span>
                            </span>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6 col-lg-4 formGroup">
                            <label class="form-label">Dealer Name*</label>
                            <input type="text" placeholder="" name="dealerName" formControlName="dealerName"
                                [ngClass]="{ 'error': submitted && f['dealerName'].errors }" required>
                            <span *ngIf="submitted && f['dealerName'].errors" class="help-block form-error">
                                <span *ngIf="f['dealerName'].errors['required']">This is a required field</span>
                            </span>
                        </div>
                        <div class="col-md-6 col-lg-4 formGroup">

                            <label class="form-label">Dealer Province*</label>
                            <select name="province" formControlName="province"
                                [ngClass]="{ 'error': submitted && f['province'].errors }" class="form-control">
                                <option value="" class="" selected="selected">Please select</option>
                                <option value="Eastern Cape">Eastern Cape</option>
                                <option value="Free State">Free State</option>
                                <option value="Gauteng">Gauteng</option>
                                <option value="KwaZulu Natal">KwaZulu Natal</option>
                                <option value="Limpopo">Limpopo</option>
                                <option value="Mpumalanga">Mpumalanga</option>
                                <option value="Northern Cape">Northern Cape</option>
                                <option value="North West">North West</option>
                                <option value="Western Cape">Western Cape</option>
                            </select>
                            <span *ngIf="submitted && f['province'].errors" class="help-block form-error">
                                <span *ngIf="f['province'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-12 col-lg-4 formGroup">
                            <label class="form-label">Dealer City*</label>
                            <input type="text" placeholder="" name="dealerCity" formControlName="dealerCity"
                                [ngClass]="{ 'error': submitted && f['dealerCity'].errors }" required>
                            <span *ngIf="submitted && f['dealerCity'].errors" class="help-block form-error">
                                <span *ngIf="f['dealerCity'].errors['required']">This is a required field</span>
                            </span>
                        </div>


                        <div class="col-md-12 formGroup">
                            <label class="form-label">Enquiry*</label>
                            <textarea name="enquiry" formControlName="enquiry"
                                [ngClass]="{ 'error': submitted && f['enquiry'].errors }" class="form-control" rows="3">
                            </textarea>
                            <span *ngIf="submitted && f['enquiry'].errors" class="help-block form-error">
                                <span *ngIf="f['enquiry'].errors['required']">This is a required field</span>
                            </span>
                        </div>
                    </div>
                    <div class="alert notification" role="alert" *ngIf="alertResponse !='' "
                        [ngClass]="alertResponse==='success' ? 'alert-success':'alert-danger'">
                        <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button
                                type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
                    </div>
                    <button type="submit" class="button button-g-blue" *ngIf="alertResponse===''">Submit
                        Request</button>
                </form>
            </div>
        </div>
    </section>

</main>