<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/privacy.jpg" alt="ALPHERA Privacy Statement" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Financial Services Privacy Statement</h1>
                        <p>At ALPHERA Financial Services, we take your privacy seriously and treat your personal
                            information with the greatest level or care, confidentiality and integrity. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/privacy-statement">Privacy Statement</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </section>


    <section class="pTB bgWhite">
        <div class="container">
            <div class="containerWrapper">
                <h3>ALPHERA Financial Services Privacy Statement</h3>

                <p>ALPHERA Financial Services, a division of BMW Financial Services (South Africa) (Pty) (Ltd)
                    ("ALPHERA Finance") takes your privacy and the protection of your personal
                    information very seriously, and we will only use your personal information in accordance with this
                    Statement and Applicable Laws. You hereby indemnify and hold ALPHERA Finance harmless from any loss,
                    damages or injury that you may incur as a result of any unintentional disclosures of your personal
                    information to unauthorised persons or the provision of incorrect or incomplete personal information
                    to ALPHERA Finance.</p>
                <p><strong>What is the ALPHERA Finance Privacy Statement?</strong><br>
                    This Statement sets out how your personal information will be used by ALPHERA Finance and applies to
                    any information, including personal and special personal information that you give to ALPHERA
                    Finance.</p>
                <p>It is important that you read this Statement carefully before submitting any personal information to
                    ALPHERA Finance.</p>
                <p>By submitting any personal information to ALPHERA Finance you provide consent to the processing of
                    your personal information as set out in this Statement.</p>
                <p>The provisions of this Statement are subject to mandatory, unalterable provisions of Applicable Laws.
                </p>
                <p>Please do not submit any personal information to ALPHERA Finance <strong>if you do not agree</strong>
                    to any of the provisions of this Statement. If you do not consent to the provisions of this
                    Statement, or parts of the Statement, ALPHERA Finance may not be able to provide its products and
                    services to you.</p>
                <p><strong>Important Terms:</strong><br>
                    "Applicable Laws" means local, foreign and international laws, regulations,
                    treaties and codes, for example the Financial Intelligence Centre Act 38 of 2001, the National
                    Credit Act 34 of 2005 and the Protection of Personal Information Act 4 of 2013.</p>
                <p>"ALPHERA Finance" or "we" or "Our" means ALPHERA
                    Financial Services ("ALPHERA Finance"), a division of BMW Financial Services (South
                    Africa) (Pty) Ltd (Registration number: 1990/004670/07), an authorised Financial Services (FSP
                    no.4623) and Registered Credit Provider NCRCP2341.</p>
                <p>"ALPHERA dealer" means a non-exclusive commercial vehicle dealership which operates
                    in its own name, its own account and at its own risk acts as a dealer for ALPHERA Finance.</p>
                <p>"Client" or "you" or "your" means any prospective,
                    new or existing client of any ALPHERA Finance, ALPHERA Finance dealers. </p>
                <p>"Personal Information" means information relating to an identifiable, living natural
                    person and where applicable, an identifiable existing juristic person as defined by Applicable Laws.
                </p>
                <p><strong>Are ALPHERA Finance dealer partners included in this Privacy Statement?</strong><br>
                    If you purchase a vehicle from an approved ALPHERA Finance dealer or otherwise give your information
                    to such entity, you will have a separate relationship with the entity in relation to its use of your
                    personal information. For the sake of clarity, it must be borne in mind that approved ALPHERA
                    Finance dealers are separate legal entities from ALPHERA Finance. If you have any queries relating
                    to any approved ALPHERA dealer which makes use of your personal information, you must contact the
                    relevant approved ALPHERA dealer directly.</p>
                <p><strong>Can this Privacy Statement be amended?</strong><br>
                    We may amend this Statement from time to time for any of the following reasons:</p>
                <ul>
                    <li>to provide for the introduction of new systems, mobile applications, website, methods of
                        operation, services, products, property offerings or facilities;</li>
                    <li>to reflect an actual or expected change in market conditions or general financial services or
                        lending practices;</li>
                    <li>to comply with changes to any Applicable Laws;</li>
                    <li>to ensure that this Statement is clearer and more favourable to you;</li>
                    <li>to rectify any mistake that might be discovered from time to time; and/or</li>
                    <li>for any other reason which ALPHERA Finance, in its sole discretion, may deem reasonable or
                        necessary.</li>
                </ul>
                <p>Any such amendment will come into effect and become part of any agreement you have with ALPHERA
                    Finance when notice is given to you of the change by publication on our website. It is your
                    responsibility to check the website often.</p>
                <p><strong>How does ALPHERA Finance handle security and privacy on the website?</strong><br>
                    We endeavour to keep our website secure at all times. We do however advise you that we cannot
                    guarantee the security of any information provided to us or by us through our website, email,
                    internet or social media. We cannot be held responsible for any loss or unauthorised use or
                    interception of information transmitted via the internet which is beyond our control.</p>
                <p><strong>Third party websites</strong><br>
                    Our website may contain links to other websites outside of the ALPHERA Finance. We are not
                    responsible for the content, privacy or security of other websites.</p>
                <p><strong>Cookies</strong><br>
                    Cookies are information that is used to track visitor use of a website and to compile statistical
                    reports on website activity. If you wish to restrict or block cookies, you can do this through your
                    browser settings.</p>
                <p><strong>Social Plugin</strong><br>
                    We use social plugins of social networks such as Facebook, YouTube, LinkedIn, Google+ and Twitter.
                </p>
                <p>Please note that we have no influence on or control over the extent of the data retrieved by the
                    social networks' interfaces and we can accordingly not to be held responsible or liable for any
                    processing or use of personal information transmitted via these social plugins. For information on
                    the purpose and extent of the data retrieval by the social network concerned, and about the rights
                    and settings possibilities for the protection of your private sphere, please refer to the data
                    protection information provided by the social network in question.</p>
                <p><strong>What personal information does ALPHERA Finance collect and how is it collected?</strong><br>
                    We may collect the following information about you:</p>
                <ul>
                    <li>your name, address, contact details, date of birth, place of birth, identity number, passport
                        number, bank details, details about your employment, tax number, VAT number, financial
                        information, and Broad Based Black Economic Empowerment related details;</li>
                    <li>information about your directors and beneficial owner if we are required to do so in terms of
                        Applicable Laws;</li>
                    <li>records of correspondence or enquiries from you or anyone acting on your behalf;</li>
                    <li>details of transactions you or any other authorised person carry out with us;</li>
                    <li>details of contracts, sales or leases you or any other authorised person carry out with us;</li>
                    <li>your credit history, where you have applied for credit or where we have agreed to provide credit
                        to you; and</li>
                    <li>sensitive or special categories of personal information, including biometric information, such
                        as images, fingerprints and voiceprints;</li>
                    <li>any other information we may require, and which is relevant to the supply of goods or services
                        to you or the provision of goods and services to ALPHERA Finance.</li>
                </ul>
                <p>If you are under 18, please do not provide us with any personal information unless you have the
                    permission of your parent or legal guardian to do so.</p>
                <p><strong>How does ALPHERA Finance collect information?</strong><br>
                    You may provide personal information to us either directly or indirectly, by completing an
                    application for our products and services or requesting further information about our products and
                    services, whether in writing, through our website, over the telephone, through an ALPHERA Finance
                    authorised dealer or any other means.</p>
                <p>We may receive information about you:</p>
                <ul>
                    <li>when you contact us directly , either via our website, our customer service teams, web chat
                        service, by email, telephone or via social media, whether to apply for one of our products or
                        services or to make an enquiry or other request;</li>
                    <li>from our network of authorised ALPHERA Finance dealer(s);</li>
                    <li>from ALPHERA Finance affiliates and business partners who provide products and services under
                        the ALPHERA Finance brand;</li>
                    <li>from third parties, such as credit bureaus, when we do credit checks or decide whether to enter
                        into an agreement with you; or</li>
                    <li>occasionally from other third parties who may lawfully pass your information on to us.</li>
                </ul>
                <p>Telephone calls to us may be recorded and/or monitored for training and quality assessment purposes.
                </p>
                <p><strong>How does ALPHERA Finance process and store my information?</strong><br>
                    Is my information safe with ALPHERA Finance?<br />
                    We continuously implement technical and organisational security measures in order to protect the
                    data we hold against unauthorised access as well as accidental or wilful manipulation, loss or
                    destruction.
                </p>
                <p>
                    It is also important that you take all necessary and appropriate steps to protect your personal
                    information yourself (for example, by ensuring that all login details, including passwords and
                    access codes are kept secure).
                </p>
                <p>
                    How is my personal information processed?<br />
                    We take the privacy and protection of your personal information seriously and will only process your
                    personal information in accordance with the Applicable Laws and this Statement.
                </p>
                <p>
                    Accordingly, ALPHERA Finance will comply with the relevant data privacy principles or conditions
                    relating to the processing (including, but not limited to, the collection, handling, transfer,
                    sharing, correction, storage, archiving and deletion) of your personal information set out in
                    Applicable Laws.
                </p>
                <p>
                    ALPHERA Finance employees attend regular data protection training. Your personal information will
                    only be processed for purposes set out in this Statement or if we are entitled to do so under
                    Applicable Laws.
                </p>

                <p>
                    <strong>Is my information safe with ALPHERA Finance?</strong><br>
                    We continuously implement technical and organisational security measures in order to protect the
                    data we hold against unauthorised access as well as accidental or wilful manipulation, loss or
                    destruction.
                </p>
                <p>It is also important that you take all necessary and appropriate steps to protect your personal
                    information yourself (for example, by ensuring that all login details, including passwords and
                    access codes are kept secure).</p>
                <p><strong>How is my personal information processed?</strong><br>
                    We take the privacy and protection of your personal information seriously and will only process your
                    personal information in accordance with the Applicable Laws and this Statement.</p>
                <p>Accordingly, ALPHERA Finance will comply with the relevant data privacy principles or conditions
                    relating to the processing (including, but not limited to, the collection, handling, transfer,
                    sharing, correction, storage, archiving and deletion) of your personal information set out in
                    Applicable Laws.</p>
                <p>ALPHERA Finance employees attend regular data protection training. Your personal information will
                    only be processed for purposes set out in this Statement or if we are entitled to do so under
                    Applicable Laws.</p>
                <p><strong>Can ALPHERA Finance retain my information?</strong><br>
                    We may retain your personal information for as long as we may require in accordance with retention
                    periods prescribed in Applicable Laws. However, where you object to such statutory retention period,
                    we will only retain it if we are permitted or required to do so in terms of Applicable Laws.
                    However, we may need to retain your personal information for longer and lawful purpose (for example,
                    for the purposes of complaints handling, legal processes and proceedings).</p>
                <p><strong>What rights do I have regarding my personal information?</strong><br>
                    You have the right to contact relevant credit bureau(x), to have the credit record(s) disclosed and
                    to correct any inaccurate information.</p>
                <p>You have a right in certain circumstances to request the destruction or deletion of and, where
                    applicable, to obtain restriction on the processing of personal information held about you. If you
                    wish to exercise this right, please contact us using the contact details set out in our PAIA Manual.
                </p>
                <p>You have a right to object on reasonable grounds to the processing of your personal information where
                    we claim such processing is necessary to pursue our legitimate interests, your legitimate interests
                    or the legitimate interests of a third party to which the information is supplied. We may then stop
                    such processing, unless Applicable Laws provide for such processing.</p>
                <p>You may request details of personal information which we hold about you under the Promotion of Access
                    to Information Act 2 of 2000 ("PAIA"). Fees to obtain a copy or a description of
                    personal information held about you are prescribed in terms of PAIA. Confirmation of whether or not
                    we hold personal information about you may be requested free of charge. If you would like to obtain
                    a copy of your personal information held by ALPHERA Finance, please review our PAIA Manual for the
                    relevant process to follow, and where applicable, the fees to be paid before such information is
                    shared to you.</p>
                <p><strong>Can ALPHERA Finance share my information?</strong><br>
                    We may share your personal information with:</p>
                <ul>
                    <li>our agents, sub-contractors and third parties who process personal information on our behalf, or
                        business partners, in South Africa and in other countries;</li>
                    <li>our authorised ALPHERA Finance dealer(s);</li>
                    <li>our service providers and agents who perform services on our behalf;</li>
                    <li>any person who has agreed or is considering providing security for your indebtedness, including
                        any surety, guarantor, potential surety or potential guarantor under any finance document who
                        requests such information to evaluate any actual or potential liability under such suretyship or
                        guarantee;</li>
                    <li>a prospective buyer or seller of any of our businesses, assets or debt;</li>
                    <li>a person who acquires substantially all of the assets of ALPHERA Finance;</li>
                    <li>any person if we are under a duty to disclose or share your personal information in order to
                        comply with any Applicable Laws, or to protect the rights, property or safety of ALPHERA
                        Finance, its clients or other third parties;</li>
                    <li>other lenders or banks, collection agencies, lawyers, accountants, auditors, regulators and law
                        enforcement agencies, to the extent necessary to protect, preserve or enforce ALPHERA Finance's
                        rights and interests in respect of any agreement or any finance document or to respond to any
                        legal enquiry or to comply with any regulatory obligation;</li>
                    <li>any other ALPHERA Finance business partner and any assignee or potential assignee of ALPHERA
                        Finance's rights and/or obligations under any agreement or any person with whom any ALPHERA
                        Finance business partners may otherwise consider entering into a contract in relation to a
                        relevant finance document; and</li>
                    <li>your agent or any other person acting on your behalf, an independent financial advisor or an
                        introducer, informing them of the outcome of your application and whether we have agreed to
                        provide you with the product, service or offering you have applied for. We may also disclose
                        information about you and your relationship with ALPHERA Finance to such person throughout the
                        term of that relationship.</li>
                </ul>
                <p>We may disclose your personal information to these third parties for any purpose permitted in terms
                    of Applicable Laws, including to -</p>
                <ul>
                    <li>assess and monitor any of your applications for ALPHERA Finance's products or services,
                        including the risk involved to comply with Applicable Laws; </li>
                    <li>determine which products and services may be of interest to you and/or to send you information
                        about such products and services, unless you object or choose not to receive such
                        communications;</li>
                    <li>expedite credit applications;</li>
                    <li>make decisions regarding the extension of credit;</li>
                    <li>keep your usual contact within ALPHERA Finance informed of the progress of any new applications
                        for new services or products, and the other way around;</li>
                    <li>have a better understanding of your circumstances and needs to provide and improve ALPHERA
                        Finance’s products and services;</li>
                    <li>comply with Applicable Laws requiring ALPHERA Finance and/or any third party to collect personal
                        information;</li>
                    <li>identify whether other financial institutions have received payment from you due to us from you;
                    </li>
                    <li>detect and report fraud and criminal activities, to identify the proceeds of unlawful activities
                        and to combat crime;</li>
                    <li>use for marketing purposes; and</li>
                    <li>make reports to any regulator or supervisory authority, including those in foreign
                        jurisdictions, if ALPHERA Finance is required to do so in terms of Applicable Laws.</li>
                </ul>
                <p>We do not share your personal information with any third parties, except if:</p>
                <ul>
                    <li>we are legally permitted or obliged to provide such information for legal or regulatory
                        purposes;</li>
                    <li>we are required to do so for purposes of existing or future legal proceedings;</li>
                    <li>we are selling one or more of our businesses to someone to whom we may transfer our rights under
                        any agreement we have with you;</li>
                    <li>necessary for purposes of preventing fraud, loss, bribery or corruption;</li>
                    <li>they perform services and process personal information on our behalf;</li>
                    <li>it is required in order to provide or manage any information, products and/or services to you;
                    </li>
                    <li>needed to help us improve the quality of our products and services.</li>
                </ul>
                <p>If you do not wish us to disclose this information to third parties, please contact us at the contact
                    details set out herein. Should you elect to not have your information shared with third parties, we
                    may not be able to provide products or services to you if such disclosure is necessary. </p>
                <p><strong>Can I share information with ALPHERA Finance on third parties?</strong><br>
                    Where you provide us with the personal information of third parties you should take steps to inform
                    the third party that you need to disclose their details to us and identify us. We will process their
                    personal information in accordance with this Statement and Applicable Laws. If you give us
                    information on behalf of someone else, you confirm to us that you have their permission to do so and
                    that they are aware of the contents of this Statement and do not have any objection to us processing
                    their information in accordance with this Statement and Applicable Laws.</p>
                <p><strong>What is my personal information used for?</strong><br>
                    Subject to Applicable Laws, we may use your personal information for a variety of purposes,
                    including:</p>
                <p><strong>Legal Contractual</strong></p>
                <ul>
                    <li>legal or contractual purposes;</li>
                    <li>to manage your credit agreement or other contractual arrangement or relationship;</li>
                    <li>to detect and prevent fraud and money laundering and/or in the interest of security and crime
                        prevention;</li>
                    <li>to conduct sanction party list and politically exposed person screening against any relevant
                        list which ALPHERA Finance may in its sole discretion determine;</li>
                    <li>to verify your identity;</li>
                    <li>to conduct due diligence enquiries on authorised ALPHERA Finance dealers and clients for
                        purposes of dealer support, dealer maintenance, vendor management, vendor payments, sanctions
                        screening or for any other purpose ALPHERA Finance may deem fit; and</li>
                    <li>to comply with Applicable Laws, including lawful requests for information received from local or
                        foreign law enforcement, government and tax collection agencies.</li>
                </ul>
                <p><strong>Marketing & Data Analytics/Enrichment</strong></p>
                <ul>
                    <li>data analytics and/or enrichment;</li>
                    <li>to carry out analysis and customer profiling;</li>
                    <li>to identify other products and services which might be of interest to you;</li>
                    <li>to assess and deal with complaints and requests;</li>
                    <li>to conduct market research and provide you with information about ALPHERA Finance's products or
                        services from time to time via email, telephone or other means (for example, events);</li>
                    <li>where you have unsubscribed from certain direct marketing communications, for the purposes of
                        ensuring that we do not send such direct marketing to you again;</li>
                </ul>
                <p><strong>Financial</strong></p>
                <ul>
                    <li>to help us recover debts;</li>
                    <li>to take credit decisions where you have applied for credit or where we have agreed to provide
                        credit to you;</li>
                    <li>to request your consumer credit reports or those of any security provider from one or more
                        credit reporting agency in connection with any agreement between you and a ALPHERA Finance’s
                        business partner and where applicable, on your direction for us to do so;</li>
                    <li>to use your credit score or other electronic data-check mechanisms when considering any credit
                        application and when providing you with services and products, and verifying any information
                        provided by you and making whatever enquiries we deem necessary from any source whatsoever to
                        establish your creditworthiness;</li>
                    <li>to conduct financial due diligence enquiries on authorised ALPHERA Finance dealers for purposes
                        of conducting creditworthiness checks or taking a decision to grant credit; and</li>
                    <li>to transmit to a registered credit bureau any information concerning your application for
                        credit, our agreement with you, termination and non-compliance with the terms thereof, as well
                        as information about your credit agreement.</li>
                </ul>
                <p><strong>Credit Application</strong><br>
                    In instances where you have applied for credit or where we have agreed to provide credit to you, we
                    may conduct searches at credit bureaus and other third parties. Details of our searches will be kept
                    by the credit bureau and will be available to other businesses that conduct searches with that
                    agency for the purposes of meeting our regulatory obligations, credit assessments, debt recovery,
                    and management of your accounts, prevention of money laundering and fraud and statistical analysis.
                    In instances where you apply for credit, we will tell you, where required by Applicable Laws, if we
                    make a decision about you relating to your credit scoring or eligibility for our products or
                    services based solely on an automated decision-making process. You will be given a reasonable
                    opportunity if permitted by Applicable Laws or a code of conduct to make representations about a
                    decision.</p>
                <p><strong>Operational & Customer Service</strong></p>
                <ul>
                    <li>to obtain a single view of an ALPHERA Finance customer;</li>
                    <li>to improve your customer experience;</li>
                    <li>to help us improve the quality of our products and services;</li>
                    <li>to provide you with the services, products or offerings you have requested, and to notify you
                        about important changes to these services, products or offerings;</li>
                    <li>to comply with your instructions or requests;</li>
                    <li>for operational, marketing, auditing, legal and record-keeping requirements;</li>
                    <li>to transfer or process your personal information outside of the Republic of South Africa to such
                        countries that may not offer the same level of data protection as the Republic of South Africa,
                        including for cloud storage purposes and the use of any of our websites. We will however only
                        transfer your personal information across South African borders if the relevant situation
                        requires trans-border processing and will do so only in accordance with Applicable Laws.</li>
                    <li>to record, assess and/or monitor your telephone calls and electronic communications to/with
                        ALPHERA Finance, including all forms of notifications, correspondence received by or sent from
                        ALPHERA Finance (including its employees, agents or contractors) in order to accurately carry
                        out your instructions and requests, to use as evidence and in the interests of crime prevention
                        and to comply with Applicable Laws;</li>
                    <li>In circumstances where we wish to protect our legitimate interest, where:</li>
                    <ul>
                        <li>we have exercised our right to terminate the agreement through a proper legal process as a
                            result of default in respect of any of your obligations under an agreement; or</li>
                        <li>a court order has been granted in our favour for the return of the vehicle;
                            we may enable vehicle tracking (by means of existing in-car functionality) to locate the
                            vehicle. Such vehicle tracking may involve the processing of your personal information, such
                            as your location or address;</li>
                    </ul>
                    <li>to disclose your personal information to third parties, including other ALPHERA Finance's
                        business partners, for reasons set out in this Statement or where it is not unlawful to do so;
                    </li>
                    <li>to conduct surveys; and</li>
                    <li>to improve or evaluate the effectiveness of ALPHERA Finance's business or products, services or
                        offerings.</li>
                </ul>
                <p><strong>What should I do if my personal details change?</strong><br>
                    We strive to maintain the integrity and accuracy of your personal information at all times. You are
                    responsible for informing us of any change in your details, such as a change of address. You have a
                    right to ask us to correct any inaccuracies in the information we hold about you.</p>
                <p>If your personal information changes at any time or our records appear to be incorrect, please inform
                    us immediately so that we may update or correct our records accordingly. If you fail to keep your
                    information updated, or if your information is incorrect, ALPHERA Finance may limit the products and
                    services offered to you or elect not to provide any of these to you.</p>
                <p><strong>Can ALPHERA Finance contact you?</strong><br>
                    We may contact you by post, telephone, e-mail, SMS and other electronic means, to inform you about
                    services, products and offerings available from ALPHERA Finance, specific ALPHERA Finance business
                    partners, or selected third parties, which we believe may be of interest to you, unless you have
                    unsubscribed from receiving such communications. We will only send you marketing communications if
                    we are entitled to do so under Applicable Laws.</p>
                <p>Your participation is completely voluntary. You may unsubscribe or opt out from receiving such
                    communications from ALPHERA Finance by accessing your online customer profile by making use of the
                    "Unsubscribe" option available on the relevant communication or by contacting our <a
                        routerLink="/contact-us">Customer Contact Centre</a> at any time.</p>
                <p>You may also contact us utilising the contact details set out in this Statement if you have
                    previously asked not to receive marketing communications from us, but would now like to hear from us
                    with news on ALPHERA Finance and other associated products and services. You may also utilise our
                    online platform <a href="https://app.bmwfinance.co.za/sf/SFMyAccountAlphera/dist/index.html"
                        target="_blank">customer portal</a>.</p>
            </div>
        </div>
    </section>

    <section class="pTB">
        <div class="container">
            <div class="containerWrapper">
                <div class="cardBox bgWhite contact col-md-6">
                    <h4>ALPHERA Finance</h4>
                    <h5>Information Officer: Amith Patel</h5>
                    <ul>
                        <li><i class="icon icon--email"></i><span>E-Mail address: <a
                            href="mailto:Information_officer@alpherafinance.co.za">Information_officer&#64;alpherafinance.co.za</a></span></li>
                        <li><i class="icon icon--map-marker"></i><span>Our address: 1 Bavaria Avenue, Ext. 17, Randjespark, Midrand, 1685.</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>


</main>