import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.less']
})
export class InsuranceComponent implements OnInit {

  constructor( 
    private router: Router,
    private helperSVC: HelperService
  ) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.checkUrl('/insurance')
      }
      if (event instanceof NavigationEnd) {
        this.checkUrl('/insurance')
      }
    });
  }
  parent: boolean = false;

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Insurance | BMW Aphera'`);
    this.checkUrl('/insurance')
  }

  

  checkUrl(reqPath: string){
    const pathName = window.location.pathname;
    const combinedPath = reqPath+'/';
    
    if(pathName.indexOf(reqPath) != -1 && pathName.indexOf(combinedPath) === -1){
      this.parent = true;
    }
    else{
      this.parent = false;
    }
  }

}
