<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/tyre-rim.jpg" alt="ALPHERA Tyre & Rim Cover" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Tyre & Rim Cover</h1>
                        <p>Your tyres are the first point of contact with the road, isn’t it worth ensuring they are
                            always in great condition and road ready. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/insurance">Insurance</a></li>
                        <li><a routerLink="/insurance/tyre-rim-cover">ALPHERA Tyre & Rim Cover</a></li>
                    </ul>
                </div>
                <!-- <h3>Always ready for the road</h3>
                <p>Life's journey sometimes leaves its mark on your vehicle and in many instances tyres bear the brunt
                    of misfortune. You now have peace of mind with innovative cover that ensures you will never have to
                    compromise when it comes to safety and performance.</p> -->
            </div>
        </div>
    </section>

    <section class="pTB">
        <div class="container">
            <div class="containerWrapper">
                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5 order-md-1">
                        <img src="assets/img/tyre-rim.jpg" alt="Cover that delivers improved traction"
                            class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pE">
                            <h3>Cover that delivers improved traction</h3>
                            <p>
                                Enjoy specialised tyre and rim cover which includes:
                            </p>
                            <ul>
                                <li>Regular and Runflat tyres covered.</li>
                                <li>Tyre fitment fee covered up to a maximum of R500 when fitted by an approved
                                    Dealership.</li>
                                <li>Tread used is not calculated at claim stage. Tyres must be in a roadworthy condition
                                    and have SA legal tread limit.</li>
                                <li>No excess payable at claim stage.</li>
                                <li>All 4 tyres covered at any given incident.</li>
                                <li>Unlimited number of claims on regular tyres (limited to R45 000 per annum on Runflat
                                    tyres).</li>
                            </ul>


                            <a href="javascript:void(0);" (click)="goToProduct('Tyre Cover')" class="button button-g-blue mb-3">Request Quote</a>
                            <a href="./assets/files/ALPHERA-Rim-and-Tyre-Cover.pdf" class="button button-ghost mb-3" target="_blank">Download Brochure</a>
                            <a href="./assets/files/ALPHERA_Tyre_Cover_Claims_Procedure.pdf" class="button button-ghost mb-3" target="_blank">Download Claims Procedure</a>
                            <a href="./assets/files/FPP10992_ALPHERA_Tyre_Cover_claim_form.pdf" class="button button-ghost" target="_blank">Download Claim Form</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bgPattern pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <app-insurance-teasers [insuranceTypeToChild]="'tyre-rim-cover'"></app-insurance-teasers>
            </div>
        </div>
    </section>

    <section class="bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Need a quote or support?</h3>
                <p>Whether it’s a custom quote for the insurance product you have your eye on, or you need a support
                    from our experienced insurance team, we are here to assist you on your journey. Please don’t
                    hesitate to get in touch for any questions, enquires or feedback. We are looking forward to hearing
                    from you.</p>

                <div class="col-md-6">
                    <app-top-up-tyre-rim-extended [showBtn]="'Yes'"
                        [incomingProduct]="'Tyre Cover'"></app-top-up-tyre-rim-extended>
                </div>
            </div>
        </div>
    </section>

</main>