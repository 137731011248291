<main>
    <section class="heroHeader pb-2 pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/request-quote.jpg" alt="ALPHERA Insurance - Request a Quote" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Insurance - Request a Quote</h1>
                        <p>Looking for cover you can rely on? Get in touch to request a quote on one of the tailored insurance solutions from ALPHERA.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/request-quote">Request a quote</a></li>
                    </ul>
                </div>
                <h3>Need a quote or support? </h3>
                <p>Whether it’s a custom quote for the insurance product you have your eye on, or you need a support
                    from our experienced insurance team, we are here to assist you on your journey. Please don’t
                    hesitate to get in touch for any questions, enquires or feedback. We are looking forward to hearing
                    from you.</p>

            </div>
        </div>
    </section>

    <section class="pTB contactSec">
        <div class="container">
            <div class="containerWrapper">
                <!-- <h3>Contact Us</h3> -->
                <div *ngIf="iframeIsLoading" class="iframe-loader"><img src="./assets/img/iframe-loading.svg"><br>Loading....</div>
                <iframe [src]="iframeUrl" frameborder="0" *ngIf="messageStus.status!=='success' && messageStus.status!=='failed'" (load)="myLoadEvent()"></iframe>

                <div class="response-message" *ngIf="messageStus.status==='success' || messageStus.status==='failed'" [class.danger]="messageStus.status==='failed'" [class.success]="messageStus.status==='success'">
                    <h4>{{messageStus.message}}</h4>
                    <p *ngIf="messageStus.status==='success'">We will be in contact with you shortly</p>
                </div>
            </div>
        </div>
    </section>


</main>