<main *ngIf="parent">
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/finance.jpg" alt="" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Finance Products</h1>
                        <p>ALPHERA Financial Services is a leading provider of multi-brand vehicle finance products. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/finance">Finance</a></li>
                    </ul>
                </div>
                <!-- <h3>Choose between Instalment Sale or Edge</h3>
                <p>When it comes to selecting the finance product that’s right for you, ALPHERA Financial Services provides you with the advantage of choice and the flexibility of more than one end of term option. From an Instalment Sale, with or without a Balloon to Edge financing, a 50:50 payment plan that allows you to pay 50% of your purchase price upfront and pay off the rest with much lower monthly instalments; your dream vehicle is within reach.  </p> -->
            </div>
        </div>
    </section>

    <section class="bgMarineBlue productHighlight">
        <div class="container">
            <div class="containerWrapper">
                <h2>Our finance products</h2>
                <div class="row">
                    <div class="col-md-6">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/instalment-sale.jpg" alt="" class="img-fluid">
                            </div>
                            <div class="teaserDescription">
                                <h3>Instalment Sale</h3>
                                <h4>4 ways to customise your contract</h4>
                                <p>Our flexible instalment sale plan allows you to drive away in your dream car. At the end of your term, you decide if you want to keep the vehicle, sell it, or trade it in.</p>
                                <a [routerLink]="'/finance/instalment-sale'" class="simpleLink">Learn more </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/edge.jpg" alt=""
                                    class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>Edge</h3>
                                <h4>The best of both</h4>
                                <p>Edge is an innovative 50:50 payment plan which gives you the benefit of not having to deplete your savings or withdraw from your bond to purchase the car of your dreams in cash.</p>
                                <a routerLink="/finance/edge" class="simpleLink">Learn more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <section class="bgPattern bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
            <div class="row justify-content-between align-items-center teaserHorizontal">
                <div class="col-md-5">
                    <img src="assets/img/insurance.jpg" alt="Car finance built on quality" class="img-fluid">
                </div>
                <div class="col-md-7">
                    <div class="teaserCopy pS">
                    <h2>ALPHERA Insurance Cover</h2>
                    <p>Now, ALPHERA customers who already enjoy our efficiency and quality of service in motor vehicle finance can also enjoy cover that goes one step further with our extensive range of insurance solutions.</p>

                        <a routerLink="/insurance" class="button button-g-blue">Explore Insurance </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </section>

</main>

<router-outlet></router-outlet>