import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2} from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactsService } from 'src/app/_services/contacts.service';
import { HelperService } from 'src/app/_services/helper.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {


  constructor(
    private contactSVC: ContactsService,
    private helperSVC: HelperService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document

   ) { }

  request_result: string = '';
  alertMsg: string = '';
  alertResponse: string = '';
  submitted: boolean = false;
  isHidden: boolean = true;
  showInsuranceField: boolean = false;
  selecttedComms: string = '';
  selectedSubject: string = '';

  contactForm = new FormGroup({
    title: new FormControl('', Validators.required),
    firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z\s\p{P}]+$/u)]),
    surname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z\s\p{P}]+$/u)]),
    eMail: new FormControl('', [Validators.required, Validators.email]),
    // contactNo: new FormControl('', [Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)]),
    prefTelephoneType: new FormControl('', Validators.required),
    // communicationMethod: new FormControl('', Validators.required),
    policyNumber: new FormControl(''),
    insuranceOffers: new FormControl(''),
    insuranceOffersReason: new FormControl(''),
    idNumber: new FormControl(''),
    contactperson: new FormControl(''),
    cellphone: new FormControl(''),
    homeTelephone: new FormControl(''),
    workTelephone: new FormControl(''),
    date: new FormControl(''),
    complaintdetails: new FormControl(''),
    subject: new FormControl('', Validators.required),
    complaintresolution: new FormControl(''),
    enquiry: new FormControl('', Validators.required),
  });

  get f() {
    return this.contactForm.controls;
  }
  ngOnInit(): void {
    this.helperSVC.setPageName(`'Contact | BMW Aphera'`);
  }

  submitForm() {
    this.submitted = true;
    if (this.contactForm.invalid) {

      return;
    }
    else {
      this.contactSVC.sendContacts(this.contactForm.value).subscribe((data)=>{
        this.resetForm();
        let Msg = 'Thank you, your contact enquiry has been submitted.';          
        this.showMessage(Msg, data.message)
      })
    }
  }

  resetForm(value: any = undefined): void {
    this.contactForm.reset(value);
    (this as { submitted: boolean }).submitted = false;
    this.request_result = '';
    this.alertMsg = '';
  }

  toggleVisibility(event: any) {
    this.isHidden = false;
    this.selecttedComms = event.target.value;
    // let phoneField = this.contactForm.get('prefTelephoneType');
    if (this.selecttedComms == 'Home telephone') {
      this.removeValidator(this.contactForm, 'cellphone');
      this.removeValidator(this.contactForm, 'workTelephone');
      this.contactForm.get('homeTelephone')?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else if (this.selecttedComms == 'Work telephone') {
      this.removeValidator(this.contactForm, 'cellphone');
      this.removeValidator(this.contactForm, 'homeTelephone');
      this.contactForm.get('workTelephone')?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else if (this.selecttedComms == 'Cellphone') {
      this.removeValidator(this.contactForm, 'workTelephone');
      this.removeValidator(this.contactForm, 'homeTelephone');
      this.contactForm.get('cellphone')?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else {
      this.removeValidator(this.contactForm, 'cellphone');
      this.removeValidator(this.contactForm, 'workTelephone');
      this.removeValidator(this.contactForm, 'homeTelephone');
    }
  }

  showMessage(message: string, status:string){
    if(message!=''){
    this.alertMsg = message;
    }
  
    if(status!=''){
    this.alertResponse = status;
    }

    setTimeout(()=>{
      this.alertMsg = '';
      this.alertResponse  = '';
    }, 10000);
  }


  toggleInsuranceVisibility(event: any){
    this.showInsuranceField = true;
    this.selectedSubject = event.target.value;

    if(this.selectedSubject == 'Insurance'){
      this.removeValidator(this.contactForm, 'contactperson');
      this.removeValidator(this.contactForm, 'idNumber');
      this.removeValidator(this.contactForm, 'date');
      this.removeValidator(this.contactForm, 'complaintdetails');
      this.removeValidator(this.contactForm, 'complaintresolution');
      this.contactForm.get('insuranceOffers')?.setValidators(Validators.required)
      this.contactForm.get('insuranceOffersReason')?.setValidators(Validators.required)
      this.contactForm.get('enquiry')?.setValidators(Validators.required);
    }
    else if(this.selectedSubject == 'Complaint'){
      this.removeValidator(this.contactForm, 'insuranceOffers');
      this.removeValidator(this.contactForm, 'insuranceOffersReason');
      this.removeValidator(this.contactForm, 'enquiry');
      this.contactForm.get('contactperson')?.setValidators(Validators.required);
      this.contactForm.get('idNumber')?.setValidators([Validators.required, Validators.maxLength(13), Validators.pattern(/^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/)]);
      this.contactForm.get('date')?.setValidators(Validators.required);
      this.contactForm.get('complaintdetails')?.setValidators(Validators.required);
      this.contactForm.get('complaintresolution')?.setValidators(Validators.required);
    }
    else{
      this.removeValidator(this.contactForm, 'insuranceOffers');
      this.removeValidator(this.contactForm, 'insuranceOffersReason');
      this.removeValidator(this.contactForm, 'contactperson');
      this.removeValidator(this.contactForm, 'idNumber');
      this.removeValidator(this.contactForm, 'date');
      this.removeValidator(this.contactForm, 'complaintdetails');
      this.removeValidator(this.contactForm, 'complaintresolution');
      this.contactForm.get('enquiry')?.setValidators(Validators.required);
    }
  }

  removeValidator(form:FormGroup, control:string){
    form.get(control)?.clearValidators();
    form.get(control)?.updateValueAndValidity();
    form.get(control)?.reset('');
  }
    
}
