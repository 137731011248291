import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.less']
})
export class FaqsComponent implements OnInit {

  constructor(private helperSVC: HelperService) { }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'FAQs | BMW Aphera'`);
  }

}
