import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StructuredDataService {

  constructor() { }

  addStructuredData(data:any){
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(data);
    document.head.appendChild(script);
  }
}
