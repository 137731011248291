<header [class.scrolled]="isScrolled ? 'scrolled':''" [class.navOpen]="showNev?'navOpen':''">
    <div class="customContainer container-fluid navWrapper">
        <div class="leftWrapper">
            <a routerLink="/"><img src="./assets/img/logo.svg" alt="alphera finance logo" class="img-fluid logoImg" /></a>
            <a href="javascript:void(0)" (click)="showMenu()" class="hamBuger">
                <span class="menuIcon"></span>
            </a>
        </div>
        <div class="rightWrapper">
            <ul class="navbar-nav menuWrapper">
                <li class="nav-item menuItem hasChild">
                    <span class="nav-link menuLink">Finance</span>
                    <ul>
                        <li><a routerLink="/finance" class="nav-link menuLink">ALPHERA finance products</a>
                            <ol>
                                <li><a routerLink="/finance/instalment-sale">Instalment Sale</a></li>
                                <li><a routerLink="/finance/edge">Edge</a></li>
                            </ol>
                        </li>

                        <li><a routerLink="/calculator" class="nav-link menuLink">Finance Calculator</a></li>
                    </ul>
                </li>
                <li class="nav-item menuItem hasChild">
                    <span class="nav-link menuLink">Insurance</span>
                    <ul>
                        <li><a routerLink="/insurance" class="nav-link menuLink">ALPHERA insurance cover</a>
                            <ol>
                                <li><a routerLink="/insurance/comprehensive-cover">ALPHERA Comprehensive Cover</a></li>
                                <li><a routerLink="/insurance/top-up-cover">ALPHERA Top-Up Cover</a></li>
                                <li><a routerLink="/insurance/care-cosmetic-repair-cover">ALPHERA Care and Cosmetic Repair Cover</a></li>
                                <li><a routerLink="/insurance/tyre-rim-cover">ALPHERA Tyre and Rim Cover</a></li>
                                <li><a routerLink="/insurance/extended-warranty-cover">ALPHERA Extended Warranty Cover</a></li>
                            </ol>
                        </li>
                    </ul>
                </li>
                <li class="nav-item menuItem"><a routerLink="/auctions" class="nav-link menuLink">Auctions</a></li>
                <li class="nav-item menuItem"><a routerLink="/dealers" class="nav-link menuLink">Dealers</a></li>
                <li class="nav-item menuItem"><a routerLink="/faqs" class="nav-link menuLink">FAQs</a></li>
                <li class="nav-item menuItem"><a routerLink="/contact-us" class="nav-link menuLink">Contact Us</a></li>
            </ul>

            <div class="menuAction">
                <a href="https://app.bmwfinance.co.za/sf/ALPHERAOnlCreditApp/index.html" class="button button-ghost" target="_blank">Finance Application</a>
                <a href="https://alpherafinance.myaccount.co.za/login" class="button button-blue" target="_blank">Login</a>
            </div>
        </div>
    </div>
</header>