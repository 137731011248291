import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-up-tyre-rim-extended',
  templateUrl: './top-up-tyre-rim-extended.component.html',
  styleUrls: ['./top-up-tyre-rim-extended.component.less']
})
export class TopUpTyreRimExtendedComponent implements OnInit {

  constructor( private router: Router) { }
  @Input()
  showBtn:string = '';

  @Input()
  incomingProduct:string = '';

  ngOnInit(): void {
  }

  goToProduct(product:string){
    // this.quoteSvc.product = product;
    sessionStorage.setItem("_ins_prod", product);
    this.router.navigate(['/request-quote']);
  }

}
