<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/edge.jpg" alt="ALPHERA - Edge" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Edge</h1>
                        <p>Edge is an innovative 50:50 payment plan which gives you the benefit of not having to deplete
                            your savings or withdraw from your bond to purchase the car of your dreams in cash.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/finance">Finance</a></li>
                        <li><a routerLink="/finance/edge">Edge</a></li>
                    </ul>
                </div>
                <!-- <h3>Looking for an alternative to cash or instalment sale?</h3>
                <p>ALPHERA Financial Services is proud to bring you Edge - our innovative financing solution that allows you to enjoy the benefits of both a cash and instalment sale. Enjoy the advantage of lower monthly payments and less interest while enjoying the flexibility of only paying 50% of the vehicle cost upfront.   </p> -->
            </div>
        </div>
    </section>

    <section class="pTB detailSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>The best of both</h3>
                <p>Edge is an innovative 50:50 payment plan which gives you the benefit of not having to deplete your savings or withdraw from your bond to purchase the car of your dreams in cash.</p>

                    <p>Instead, you can invest your savings to reap higher returns, all whilst enjoying ultra-low repayments by servicing only the interest on the remaining amount. Put down 50% of the purchase price as a deposit.
                    </p>

                    <p>
                    Benefit from lower monthly instalments by servicing the interest on the remaining amount (over 36 months). At the end of the term you can either settle the outstanding 50% balloon payment, refinance your vehicle or trade in.</p>
                    <div class="mt-4">
                        <a href="https://app.bmwfinance.co.za/sf/ALPHERAOnlCreditApp/index.html"
                            class="button button-g-blue" target="_blank">Finance Application</a>
                    </div>
                </div>
        </div>
    </section>



    <section class="pTB bgPattern bottomSec">
        <div class="container">
            <div class="containerWrapper">
                <h2>Looking for a more traditional finance product? </h2>
                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5 order-md-1">
                        <img src="assets/img/instalments-sale.jpg" alt="ALPHERA Finance instalment-sale" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pE">
                            <h2>Instalment Sale</h2>
                            <p><strong>4 ways to customise your contract</strong></p>
                            <p>Our flexible instalment sale plan allows you to drive away in your dream car. At the end of your term, you decide if you want to keep the vehicle, sell it, or trade it in.</p>

                            <a routerLink="/finance/instalment-sale" class="button button-g-blue">Explore Instalment </a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>

</main>