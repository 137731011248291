import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper';
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
Swiper.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }
  @ViewChild(SwiperComponent) swiper: SwiperComponent | undefined;
  hasPreviousSlide = false;
  hasNextSlide = false;
  ngAfterViewInit() {
    // Detect initial slide state
    this.detectSlideState();
  }
  
  ngOnInit(): void {
  }

  config: SwiperOptions = {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 10,
    pagination: {
      clickable: true
    },
    navigation: false,
    loop: false,
    speed: 1000,
    breakpoints: {
      768: {
        spaceBetween: 30
      }
    },
  }
  detectSlideState() {
    if (this.swiper && this.swiper.swiperRef) {
      this.hasPreviousSlide = this.swiper.swiperRef.isBeginning;
      this.hasNextSlide = this.swiper.swiperRef.isEnd;
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  swiperNext(){
    if (this.swiper && this.swiper.swiperRef) {
    this.swiper.swiperRef.slideNext();
    this.detectSlideState();
  }
  }

  swiperPrev(){
    if (this.swiper && this.swiper.swiperRef) {
    this.swiper.swiperRef.slidePrev();
    this.detectSlideState();
  }

  }
}
