<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/privacy.jpg" alt="ALPHERA Privacy Statement" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Cookie Policy</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
    </section>

    <section class="pTB">
        <div class="container">
            <div class="containerWrapper">
                <div id="cookiepolicy" class="container2">
                  <div id="theHeight" class="row">
              
                          <div id="contentpage" class="epaas-policy-page-container"></div>
              
                  </div>
                </div>
            </div>
        </div>
    </section>

</main>