<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/faqs.jpg" alt="ALPHERA Frequently Asked Questions" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Frequently Asked Questions</h1>
                        <p>Let us help you find an answer.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/faqs">FAQs</a></li>
                    </ul>
                </div>
                <!-- <h3>Do you have a question about ALPHERA Financial Services? </h3>
                <p>We’ve added some of our most frequently asked questions to make it simpler for you to find a solution. Please feel free to get in touch with the ALPHERA Financial Services Team if you are unable to find an answer to your query, below: 
                </p> -->
                
            </div>
        </div>
    </section>


    <section class="pTB faqsSec">
        <div class="container">
            <div class="containerWrapper">
               <h3>General Information</h3>
               <div class="accordion accordionWrapper" id="accordionGeneral">
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader" data-bs-toggle="collapse" data-bs-target="#accordion_GeneralOne" aria-expanded="true" aria-controls="accordion_GeneralOne">How does the VAT (value-added tax) increase affect my existing finance agreement?</a>
                    <div id="accordion_GeneralOne" class="accordion-collapse collapse show" data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <p><strong>Individual Customers:</strong><br/> In line with the announcement by The National Treasury of a 1% increase in VAT (value-added tax) from 14% to 15% effective 1 April 2018, the monthly service fee will be
                                adjusted from R57.00 to R57.50 or from R68.40 to R69.00 depending on the start date of your finance agreement. Accordingly, your monthly instalment will be adjusted. Please do not hesitate to get in touch on
                                0861 257 437 or <a href="mailto:Customer.Service@alpherafinance.co.za">Customer.Service@alpherafinance.co.za</a> should you have any further enquires.</p>

                                <p><strong>Corporate Customers:</strong><br/> In line with the announcement by The National Treasury of a 1% increase in VAT (value-added tax) from 14% to 15% effective 1 April 2018, the monthly service fee will
                                be adjusted, accordingly, your monthly instalment will be adjusted. Please do not hesitate to get in touch on 0861 257 437 or <a href="mailto:Customer.Service@alpherafinance.co.za">Customer.Service@alpherafinance.co.za</a> should you have any further enquires.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_GeneralTwo" aria-expanded="false" aria-controls="accordion_GeneralTwo">Early settlement?</a>
                    <div id="accordion_GeneralTwo" class="accordion-collapse collapse" data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <p>You may settle your agreement prior to the initial expiry date on your contract, however depending on the contract type and size, you may be required to pay up to 90 days’ worth of early termination penalty interest
                                – this is governed by the National Credit Act.</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_GeneralThree" aria-expanded="false" aria-controls="accordion_GeneralThree">Interest Calculation Methodology.</a>
                    <div id="accordion_GeneralThree" class="accordion-collapse collapse" data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <p>ALPHERA Financial Services’ finance payment calculation uses 12 (twelve) equal periods per year, i.e. 30/360 per period which is being applied to the early termination charge calculations. This calculation is in
                            line with the National Credit Act, No. 34 of 2005.</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_GeneralFour" aria-expanded="false" aria-controls="accordion_GeneralFour">How do I obtain my registration documents once my account is paid in full?</a>
                    <div id="accordion_GeneralFour" class="accordion-collapse collapse" data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <p>You have 3 options: </p>
                            <ul>
                                <li>Collection (turnaround time of 24-48 hours)</li>
                                <li>Courier (fee of R50.32)</li>
                                <li>Registered mail </li>
                            </ul>
                            <p>Please e-mail your request to <a href="mailto:customer.service@ALPHERAfinance.co.za?Subject=ALPHERA Finance registration documents" target="_blank">customer.service@ALPHERAfinance.co.za</a> with your preferred option
                                together with an address of where the documents are to be sent. Company requests must be on an official letterhead if the account is in the company name.</p>
                        </div>
                    </div>
                </div>
                
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_GeneralFive" aria-expanded="false" aria-controls="accordion_GeneralFive">Do I need any documents when crossing the border with a financed vehicle? </a>
                    <div id="accordion_GeneralFive" class="accordion-collapse collapse" data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <p>Yes, a cross-border letter must be obtained. A written request must be sent to <a href="mailto:Customer.Service@ALPHERAfinance.co.za?Subject=ALPHERA Finance cross-border documents" target="_blank">Customer.Service@ALPHERAfinance.co.za</a>                                            stating the following:</p>
                            <ul>
                                <li>The country to be visited.</li>
                                <li>Date of departure and return. </li>
                                <li>The latest proof of insurance confirming cover outside South Africa (and the countries that are to be visited) and dates of cover. </li>
                            </ul>
                            <p>Please note that a 72-hour turnaround time is applicable and the account must be at least 6 months old and up to date. Extreme circumstances will be considered however subject to approval.</p>
                        </div>
                    </div>
                </div>
                
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_GeneralSix" aria-expanded="false" aria-controls="accordion_GeneralSix">Can I make additional payments on my account? </a>
                    <div id="accordion_GeneralSix" class="accordion-collapse collapse" data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <p>Yes, additional payments can be made and used to reduce the instalment, term or residual. However, the client must confirm in writing how the specified funds must be allocated and declare the source of funds (FICA
                                Anti-Money Laundering regulation). Please note that the adjustment is subject to the deal structure. There are no penalties for making additional payments.</p>
                        </div>
                    </div>
                </div>
               </div>

               
               <h3>Personal Finance</h3>
               <div class="accordion accordionWrapper" id="accordionFinance">
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFinanceOne" aria-expanded="true" aria-controls="accordionFinanceOne">What are the minimum requirements to apply for vehicle finance through ALPHERA Financial Services?</a>
                    <div id="accordionFinanceOne" class="accordion-collapse collapse" data-bs-parent="#accordionFinance">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li>You (the applicant) need to be a South African citizen or have a valid permit to live and work in South Africa.</li>
                                <li>You (the applicant) need to have a valid South African driver's licence. International driver’s licenses can be accepted with certain conditions attached.</li>
                                <li>You (the applicant) need to supply comprehensive proof of income.</li>
                                <li>You (the applicant) need to have a clear credit record.</li>
                                <li>You (the applicant) need to earn a Gross Monthly Income in excess of R10 000 per month.</li>
                                <li>The amount financed needs to be more than R50 000.</li>
                                <li>The age of the vehicle at the end of the contract term must not exceed 12 years.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFinanceTwo" aria-expanded="false" aria-controls="accordionFinanceTwo">Once my finance is approved, what documents will I need to take delivery of my asset?</a>
                    <div id="accordionFinanceTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFinance">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li>Your original ID document, original driver′s licence and proof of income.</li>
                                <li>Proof of comprehensive insurance.</li>
                                <li>Any other documents as requested for at the approval stage.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFinanceThree" aria-expanded="false" aria-controls="accordionFinanceThree">I want to purchase a vehicle privately, will you finance me?</a>
                    <div id="accordionFinanceThree" class="accordion-collapse collapse" data-bs-parent="#accordionFinance">
                        <div class="accordion-body accordionBody">
                            <p>We will finance any vehicle currently financed with us, or, if the seller or buyer is a good existing client of ALPHERA Financial Services, MINI Financial Services or BMW Financial Services.</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFinanceFour" aria-expanded="false" aria-controls="accordionFinanceFour">What types of vehicles does ALPHERA finance?</a>
                    <div id="accordionFinanceFour" class="accordion-collapse collapse" data-bs-parent="#accordionFinance">
                        <div class="accordion-body accordionBody">
                            <p>ALPHERA Financial Services finances all new and used leading car and motor bike brands that comply with below mentioned criteria:</p>
                                        <ul>
                                            <li>The total age of the car at the end of the contract term must not exceed 12 years.</li>
                                            <li>GVM (Gross Vehicle Mass) must not exceed 4 000 kg’s.</li>
                                            <li>The vehicle must not be registered as a “Code 3 or 4” on the eNaTIS system.</li>
                                        </ul>
                        </div>
                    </div>
                </div>
                
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordionFinanceFive" aria-expanded="false" aria-controls="accordionFinanceFive">Can I apply for finance online? </a>
                    <div id="accordionFinanceFive" class="accordion-collapse collapse" data-bs-parent="#accordionFinance">
                        <div class="accordion-body accordionBody">
                            <p>Yes. We offer an <a href="https://app.bmwfinance.co.za/sf/ALPHERAOnlCreditApp/index.html#/qualify">online finance application</a> which provides you with feedback at completion of the application. </p>
                        </div>
                    </div>
                </div>
               </div>


               <h3>Company Finance.</h3>
               <div class="accordion accordionWrapper" id="accordionCompanyFinance">
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_companyFinanceOne" aria-expanded="true" aria-controls="accordion_companyFinanceOne">What documents are required to apply for finance in a company name?</a>
                    <div id="accordion_companyFinanceOne" class="accordion-collapse collapse" data-bs-parent="#accordionCompanyFinance">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li>Completed Company Application.</li>
                                <li>Completed Individual Finance Application for owners and shareholders.</li>
                                <li>Copy of valid ID document and driver′s licence.</li>
                                <li>Proof of business address (utility account).</li>
                                <li>Company Registration documents.</li>
                                <li>Latest Annual Financial Statements.</li>
                                <li>Latest 3 months bank statements in the company name.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
               </div>
            </div>
        </div>
    </section>


</main>