import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-instalment-sale',
  templateUrl: './instalment-sale.component.html',
  styleUrls: ['./instalment-sale.component.less']
})
export class InstalmentSaleComponent implements OnInit {

  constructor(private helperSVC: HelperService) { }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Finance-Instalment-Sale | BMW Aphera'`);
  }

}
