import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.less']
})
export class AuctionsComponent implements OnInit {

  constructor(private helperSVC: HelperService) { }

  ngOnInit(): void {
    this.helperSVC.setPageName(`'Auctions | BMW Aphera'`);
  }

}
