import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef,private router: Router) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.hideNav();
      }
    });
  }
  isScrolled: boolean = false;
  showNev: boolean = false;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // Add your logic here to determine when to add the class
    // For example, you can check the scroll position or other conditions
    const scrollY = window.scrollY; // Get the vertical scroll position
    const threshold = 50; // Adjust this threshold value as needed

    if (scrollY > threshold) {
      this.isScrolled = true;
      // this.renderer.addClass(this.el.nativeElement.ownerDocument.body, 'scrolled');
    } else {
      this.isScrolled = false;
      // this.renderer.removeClass(this.el.nativeElement.ownerDocument.body, 'scrolled');
    }
  }


  ngOnInit(): void {
  }

  showMenu(){
    // this.showNev = true;
    this.showNev = !this.showNev;
    document.body.classList.toggle('navOpen');
  }

  hideNav() {
    this.showNev = false;
    document.body.classList.remove('navOpen');
  }
}
