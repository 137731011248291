import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webchat-terms',
  templateUrl: './webchat-terms.component.html',
  styleUrls: ['./webchat-terms.component.less']
})
export class WebchatTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
