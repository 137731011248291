<header [class.scrolled]="isScrolled ? 'scrolled':''" [class.navOpen]="showNev?'navOpen':''">
    <div data-tracking-regionid="navigation¦1¦navigation¦¦¦" data-tracking-block="true" data-tracking-element-display="block" class="customContainer container-fluid navWrapper">
        <div class="leftWrapper">
            <a routerLink="/" data-tracking-linkid="image¦1¦logo¦internal click"><img src="./assets/img/logo.svg" alt="alphera finance logo" class="img-fluid logoImg" /></a>
            <a href="javascript:void(0)" (click)="showMenu()" class="hamBuger" data-tracking-linkid="link¦1¦open mobile menu¦open content">
                <span class="menuIcon"></span>
            </a>
        </div>
        <div class="rightWrapper">
            <ul class="navbar-nav menuWrapper">
                <li class="nav-item menuItem hasChild">
                    <span class="nav-link menuLink">Finance</span>
                    <ul>
                        <li><a data-tracking-linkid="link¦1¦alphera menu item - finance products¦internal click" title="link¦1¦alphera menu item - finance products¦internal click" routerLink="/finance" class="nav-link menuLink">ALPHERA finance products</a>
                            <ol>
                                <li><a routerLink="/finance/instalment-sale" data-tracking-linkid="link¦2¦alphera menu item - finance products - Instalment sale¦internal click">Instalment Sale</a></li>
                                <li><a routerLink="/finance/edge" data-tracking-linkid="link¦2¦alphera menu item - finance products - Edge¦internal click">Edge</a></li>
                            </ol>
                        </li>
                        <li><a routerLink="/calculator" class="nav-link menuLink" data-tracking-linkid="link¦3¦alphera menu item - finance products - Finance calculator¦internal click">Finance Calculator</a></li>
                    </ul>
                </li>
                <li class="nav-item menuItem hasChild">
                    <span class="nav-link menuLink">Insurance</span>
                    <ul>
                        <li><a routerLink="/insurance" class="nav-link menuLink" data-tracking-linkid="link¦3¦alphera menu item - finance products - Insurance¦internal click">ALPHERA insurance cover</a>
                            <ol>
                                <li><a routerLink="/insurance/comprehensive-cover" data-tracking-linkid="link¦4¦alphera menu item - finance products - Insurance - Comprehensive cover¦internal click">ALPHERA Comprehensive Cover</a></li>
                                <li><a routerLink="/insurance/top-up-cover" data-tracking-linkid="link¦5¦alphera menu item - finance products - Insurance - Alphera Top-Up Cover¦internal click">ALPHERA Top-Up Cover</a></li>
                                <li><a routerLink="/insurance/care-cosmetic-repair-cover" data-tracking-linkid="link¦6¦alphera menu item - finance products - Insurance - Care and cosmetic repair¦internal click">ALPHERA Care and Cosmetic Repair Cover</a></li>
                                <li><a routerLink="/insurance/tyre-rim-cover" data-tracking-linkid="link¦7¦alphera menu item - finance products - Insurance - ALPHERA Tyre and Rim Cover¦internal click">ALPHERA Tyre and Rim Cover</a></li>
                                <li><a routerLink="/insurance/extended-warranty-cover" data-tracking-linkid="link¦8¦alphera menu item - finance products - Insurance - ALPHERA Extended Warranty Cover¦internal click">ALPHERA Extended Warranty Cover</a></li>
                            </ol>
                        </li>
                    </ul>
                </li>
                <li class="nav-item menuItem"><a routerLink="/auctions" class="nav-link menuLink" data-tracking-linkid="link¦9¦alphera menu item - finance products - Auctions¦internal click">Auctions</a></li>
                <li class="nav-item menuItem"><a routerLink="/dealers" class="nav-link menuLink" data-tracking-linkid="link¦9¦alphera menu item - Dealers¦internal click">Dealers</a></li>
                <li class="nav-item menuItem"><a routerLink="/faqs" class="nav-link menuLink" data-tracking-linkid="link¦9¦alphera menu item - FAQs¦internal click">FAQs</a></li>
                <li class="nav-item menuItem"><a routerLink="/contact-us" class="nav-link menuLink" data-tracking-linkid="link¦9¦alphera menu item  - Contact us¦internal click">Contact Us</a></li>
            </ul>

            <div class="menuAction">
                <a href="https://app.bmwfinance.co.za/sf/ALPHERAOnlCreditApp/index.html" class="button button-ghost" target="_blank" data-tracking-linkid="link¦9¦alphera menu item - finance application¦internal click">Finance Application</a>
                <a href="https://alpherafinance.myaccount.co.za/login" class="button button-blue" target="_blank" data-tracking-linkid="link¦9¦alphera menu item - Login¦internal click">Login</a>
            </div>
        </div>
    </div>
</header>