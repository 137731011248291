import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.less']
})
export class CookiePolicyComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    this.loadScript();
  }

  private loadScript() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "./assets/js/epasscontainer.js";
    document.body.appendChild(chatScript);
    }

}
