<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/terms-conditions.jpg" alt="Website Terms and Conditions" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Website Terms and Conditions</h1>
                        <p>By visiting the ALPHERA website, you agree to the Terms and Conditions of use.
                            Please take a moment to read through Website Terms & Conditions and familiarise yourself
                            with its contents. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </section>


    <section class="pTB bgWhite">
        <div class="container">

            <div class="containerWrapper">
                <div class="row">
                    <div class="col-md-6">
                        <ol>
                            <li><strong>WEBSITE TERMS AND CONDITIONS OF USE.</strong><br/>
                                <ol>
                            <li>This document provides the terms and conditions of use (“terms of use”) in respect of
                                the access to and use of information, products, services and functions provided on
                                www.alphera.co.za (the “website”) owned by BMW Financial Services South Africa (Pty) Ltd
                                situated at 1 Bavaria Avenue, Randjespark Ext 17, Midrand, 1685 and having registration
                                number 1990/004670/07 (“BMW Finance”).</li>
                            <li>Should you, the user, access the website and disagree with any of the terms of use, you
                                must refrain from accessing the Website and/or using our services.</li>
                            <li>By downloading, installing or using the online credit application, you indicate that you
                                accept these terms of use and that you agree to abide by them.</li>
                            <li>Your downloading, installation or use of the website constitutes your acceptance of
                                these terms of use which takes effect upon the date on which you download, install or
                                make use of the website. Should you disagree with these terms of use you should cease
                                downloading, installing or making use of the website immediately.</li>
                            <li>The website is operated by BMW Financial Services (South Africa) (Pty) Ltd hereinafter
                                referred to as BMW Finance.</li>
                            <li>BMW Finance reserves the right to amend these terms of use and/or replace any of the
                                terms or the entire terms of use from time to time. Such amendments shall supersede and
                                replace any previous terms of use and shall be made available on the website.</li>
                            <li>You, the user, bears the responsibility of regularly reviewing information on the
                                website to obtain timely notice of such amendments. Your continued use of the website
                                after amendments have been effected constitutes your acceptance of the amended terms of
                                use.</li>
                            <li>To download, install, access or use the website you must be 18 (eighteen) years of age
                                or older. If you are under the age of (18) eighteen and you wish to use, download,
                                install, access or use the website you must obtain consent from your parents or guardian
                                before doing so.</li>
                            <li>BMW Finance shall provide you with prior notification where they have collected personal
                                information from you and the purpose for which they have collected that information and
                                will further notify you when such information is affected by an amendment to this terms
                                and conditions.</li>
                            <li>Should there be anything in these terms of use which you do not understand please
                                contact BMW Finance as soon as possible (see clause 11 below for the contact details of
                                BMW Finance). Please note that calls made to BMW Finance are charged at national call
                                rates and maybe monitored for training, security and quality assurance purposes.</li>
</ol>
</li>
                            <li><strong>CONTENTS OF THE WEBSITE</strong><br/>
                                <ol>
                            <li>BMW Finance reserves the right to make improvements, amend or discontinue, without
                                notice, any aspect or feature of the website and any information or content on the
                                website.</li>
                            <li>BMW Finance reserves the right to amend the products, prices and rates quoted on the
                                website from time to time without notice.</li>
                            <li>BMW Finance makes no representations or warranties, whether express or implied as to the
                                accuracy, completeness or reliability of any information, data and/or content on the
                                website, including but without limitation of the following:
                                <ol type="a">
                                    <li>BMW Finance does not warrant that the website, information thereon or downloads
                                        shall be error-free or that they shall meet any particular criteria of
                                        performance or quality. BMW Finance expressly disclaims all implied warranties,
                                        including, without limitation, warranties of merchant ability, fitness for a
                                        particular purpose, non-infringement, compatibility, security and accuracy;</li>
                                    <li>Whilst BMW Finance has taken reasonable measures to ensure the integrity of the
                                        website and its contents, the warranty, whether express or implied, is given
                                        that any files, downloads or application forms available via this website are
                                        free of viruses, or any other data or code which has the ability to corrupt,
                                        damage or affect the operation of the user’s system; and</li>
                                    <li>BMW Finance disclaims any responsibility for the verification of any claims.
                                        Information published on this website may be done so in the format in which BMW
                                        Finance receives it and statements from external parties are accepted as fact.
                                    </li>
                                </ol>

                            </li>
                        </ol>
                            </li>
                            <li><strong>THIRD PARTY WEBSITES AND THIRD PARTY CONTENT</strong><br/>
                                <ol>
                            <li>BMW Finance has no control over and accepts no responsibility for the content of any
                                website to which a link from the website exists unless BMW Finance is the provider of
                                those linked websites.</li>
                            <li>Such linked websites are provided “as is” for your convenience only with no warranty,
                                express or implied, for the information provided within them.</li>
                            <li>BMW Finance does not provide any endorsement or recommendation of any third party
                                website to which the website provides a link. The terms of use and privacy policies of
                                those third party websites will apply to your use of those websites and online credit
                                applications and any orders you make for goods and services via such websites.</li>
                            <li>Should you have any queries, concerns or complaints about such third party websites
                                including but not limited to queries, concerns or complaints relating to products,
                                orders for products, faulty products and refunds, you must direct them to the operator
                                of that third party website.</li>
                                
                                </ol>
                                </li>
                            <li><strong>USAGE RESTRICTIONS</strong><br/>
                                <ol>
                            <li>The user hereby agrees that it shall not itself, nor through a third party:</li>
                            <li>Use or copy any material from the website onto other websites; or</li>
                            <li>Frame the website or any part thereof onto your own or another person’s website.</li>
                        </ol>

                        </li>
                            <li><strong>SECURITY</strong><br/>
                                <ol>
                            <li>BMW Finance hereby reserves the right to take whatever action it may deem necessary to
                                preserve the security, integrity and reliability of its network and office applications
                                in order to ensure the secure and reliable operation of the services to users of the BMW
                                Finance website.</li>
                            <li>You may not utilise the website in any manner which may compromise the security of the
                                BMW Finance’s networks or tamper with the website in any manner whatsoever which shall
                                include without limitation, gaining or attempting to gain unauthorised access to the
                                website or delivering or attempting to deliver any unauthorised, damaged or malicious
                                code to the website, all of which is expressly prohibited. Any person or entity which
                                does so, or attempts to do so, shall be held criminally liable. Furthermore should BMW
                                Finance suffer any damage or loss, civil damages shall be claimed by BMW Finance against
                                the user.</li>
                            <li>Any user who commits any of the offences detailed in Chapter 13 of the Electronic
                                Communications and Transactions Act 25 of 2002 (the “ECTA”) shall, in addition to,
                                criminal prosecution, be liable for all resulting liability, loss or damages suffered
                                and/or incurred by BMW Finance and its affiliates, agents and/or partners.</li>
                        </ol>

                            </li>
                            <li><strong>INTELLECTUAL PROPERTY RIGHTS</strong><br/>
                                <ol>
                            <li>For the purposes of this clause, the following words shall have the following meanings
                                ascribed to them:
                                <ol type="a">
                                    <li>"Intellectual property rights" means all and any of the rights in and to
                                        intellectual property of any nature whatsoever owned and/or controlled directly
                                        or under licence by BMW Finance, now or in the future, including without
                                        limitation, BMW Finance’s rights, title and interest in and to all technology,
                                        source code(s), trade secrets, logos, systems, methods, trademarks, trade names,
                                        styles, insignia, designs, patents and copyright, and all similar proprietary
                                        rights which may subsist in any part of the world, whether registered or not.
                                    </li>
                                    <li>All copyright and other intellectual property rights in all content, trademarks,
                                        software data, material, including logos, databases, text, graphics, icons,
                                        hyperlinks, confidential information, designs, agreements, and multimedia works,
                                        published on or via the website (“proprietary material”), are the property of,
                                        or are licensed to BMW Finance and as such are protected from infringement by
                                        local and international legislation and treaties.</li>
                                    <li>BMW Finance is the exclusive and sole licensee of all intellectual property
                                        rights contained on the website and in the material published on it. These works
                                        are protected by copyright and trademark laws and international treaties. All
                                        such rights are reserved.</li>
                                    <li>You may print off one copy and you may download extracts of any page(s) from the
                                        website for your personal reference and you may draw the attention of others
                                        within your organization to the material available on the website.</li>
                                    <li>You must not modify the paper or digital copies of any material you have printed
                                        off or downloaded in any way and you must not use any illustrations,
                                        photographs, video or audio sequences or any graphics separately from any
                                        accompanying text.</li>
                                    <li>You must not use any part of the materials on the website for commercial
                                        purposes without obtaining consent from BMW Finance.</li>
                                    <li>Should you print off, copy or download any part of the website in breach of
                                        these terms of use, your right to the use of the website shall cease immediately
                                        and you must, at the option of BMW Finance, return or destroy any copies of the
                                        materials you have made.</li>
                                    <li>By submitting reviews, comments and/or any other content (other than your
                                        personal information) to BMW Finance from posting on the website, you
                                        automatically grant BMW Finance and its affiliates a non-exclusive,
                                        royalty-free, perpetual, irrevocable right and licence to use, reproduce,
                                        publish, translate, sub-licence, copy and distribute such content in whole or in
                                        part worldwide, and to incorporate it into other works in any form, media, or
                                        technology now known or hereinafter developed, for the full term of any
                                        copyright that may exist in such content. Subject to this licence, you retain
                                        any and all rights that may exist in such content.</li>
                                    <li>All rights not expressly granted are reserved and no right, title or interest in
                                        any proprietary material or information contained in this website is granted to
                                        you.</li>
                                    <li>Irrespective of the existence of copyright, the user acknowledges that BMW
                                        Finance is the proprietor of all material on the website, except where a third
                                        party is indicated as the proprietor, whether it constitutes confidential
                                        information or not, and that the user has no right, title or interest in any
                                        such material.</li>
                                    <li>BMW Finance authorises you only to view, copy, temporarily download to a local
                                        drive and to print the content of this website, or any part thereof, provided
                                        that such content is used for personal purposes and for information purposes
                                        only in a non-commercial manner.</li>
                                </ol>
                            </li>
                        </ol>

                        </li>
                            <li><strong>RISK, LIMITATION OF LIABILITY AND INDEMNITY</strong><br/>
                                <ol>
                            <li>The user’s use of this website and the information contained therein is entirely at the
                                user’s own risk and the user assumes full responsibility and risk of loss resulting from
                                the use thereof.</li>
                            <li>The transmission of information via the internet, including without limitation, e-mail,
                                is susceptible to monitoring and interception. The user bears all the risk of
                                transmitting information in this manner. Under no circumstances shall BMW Finance be
                                liable for any loss, harm or damage suffered by the user as a result thereof. BMW
                                Finance reserves the right to request independent verification of any information
                                transmitted via e-mail and the user consents to such verification should BMW Finance
                                deem it necessary.</li>
                            <li>To the extent permissible by law:
                                <ol type="a">
                                    <li>Neither BMW Finance, its affiliates, shareholders, agents, consultants or
                                        employees shall be liable for any damages whatsoever, including without
                                        limitation any direct, indirect, special, incidental, consequential or punitive
                                        damages howsoever arising (whether in an action arising out of statute, delict
                                        or otherwise) related to the use of, or the inability to access or use the
                                        content of the mobile application or any functionality thereof, or the
                                        information contained on the website, or any linked website even if BMW Finance
                                        knows or reasonably ought to have known or is expressly advised thereof.</li>
                                    <li>The liability of BMW Finance for damages suffered by the user, whether direct or
                                        indirect as a result of the malfunctioning of the website shall be limited to
                                        BMW Finance rectifying the malfunction within a reasonable time and free of
                                        charge, provided that BMW Finance is notified immediately of the damage or
                                        defective execution of the website. This liability shall fall away and be
                                        expressly excluded if the user attempts to correct or allows third parties to
                                        correct or attempt to correct the website without the prior written approval of
                                        BMW Finance. However, in no event shall BMW Finance be liable to the user for
                                        special, incidental, consequential or punitive losses or damages arising out of
                                        or in connection with the mobile application or its use or the delivery,
                                        installation, servicing, performance or use of it in combination with other
                                        mobile software.</li>
                                    <li>You agree to indemnify and hold BMW Finance and each of its affiliates,
                                        successors, assigns and their respective officers, directors, employees, agents,
                                        representatives, licensors, advertisers and suppliers harmless from and against
                                        any and all losses, expenses, damages, costs and expenses (including legal
                                        fees), resulting from your use of the website and/or any violation of the terms
                                        of use of this agreement. BMW Finance reserves the right to assume the exclusive
                                        defence and control of any demand, claim or action arising hereunder or in
                                        connection with the website and all negotiations for settlement or compromise.
                                        You agree to fully co-operate with BMW Finance in the defence of any such
                                        demand, claim, action, settlement or compromise negotiations as requested by BMW
                                        Finance.</li>
                                </ol>
                            </li>
                            </ol>
                            </li>
                        </ol>
                    </div>
                    <div class="col-md-6">
                        <ol start="8">
                            <li><strong>PRIVACY AND COOKIE POLICY</strong><br/>
                                <ol>
                            <li>BMW Finance takes your privacy seriously and is committed to protecting your personal
                                information. BMW Finance uses your personal information collected from you in accordance
                                with this policy.</li>
                            <li>Personal information in terms of this policy means information that can identify you as
                                an individual or is capable of identifying you as such. Personal information does not
                                mean general, statistical, aggregated or anonymised information.</li>
                            <li>Use of our services by you signifies your consent to us collecting and using your
                                personal information as specified below.</li>
                            <li>We may collect information about you in the following manners:</li>
                            <li>You may provide personal information to us when communicating with us;</li>
                            <li>When you enter a competition or participate in a promotion, we will ask you for your
                                name, address and e-mail address and any other relevant information about you.</li>
                            <li>We may use your information in the following manner:</li>
                            <li>We may use your information to confirm that your orders have been received;</li>
                            <li>To validate you as a customer when using the services of BMW Finance and calling the
                                information desk;</li>
                            <li>To prevent and detect criminal activity, fraud and misuse or damage to the services or
                                networks of BMW Finance;</li>
                            <li>To prosecute those responsible for criminal or fraudulent activity; and</li>
                            <li>To contact you with the purpose of inviting you to form part of our consumer panel or
                                various research groups.</li>
                            <li>The time periods for which BMW Finance retains your information may vary according to
                                the use or purpose attached to the information. Unless there is a specific legal
                                requirement which requires BMW Finance to retain your information, BMW Finance shall not
                                retain your information for longer than necessary for the purpose for which the
                                information was collected or for which it is to be processed.</li>
                            <li>Disclosure of your information to third parties may occur in the following manner:</li>
                            <li>BMW Finance may provide aggregate statistics about its sales, customers, traffic
                                patterns and other website information to third parties, but these statistics will not
                                include any information that could identify you;</li>
                            <li>BMW Finance allocates cookies during the registration process for our services. These
                                cookies shall retain information collected during your registration and will allow BMW
                                Finance to recognise you as a customer and provide you with the services you require.
                            </li>
                            <li>Most internet browsers are set by default to accept cookies automatically, but you can
                                usually alter the settings of your browser to prevent automatic acceptance and to prompt
                                you every time a cookie is sent to you or to allow you to choose not to receive cookies
                                at all.</li>
                            <li>Should you prefer us to desist from processing your information for marketing purposes,
                                kindly notify us in writing at the address set out in clause 11 hereunder.</li>
                        </ol>
                        </li>
                            <li><strong>CONFIDENTIALITY</strong><br/>
                                <ol>
                            <li>By subscribing as a user of this website, you agree that you shall hold in the strictest
                                confidence and not disclose to any third party information acquired in connection with
                                any aspect of the products and/or services offered by BMW Finance. You shall notify BMW
                                Finance should you discover any loss or unauthorised disclosure of the information.</li>
                            <li>Any information or material sent to BMW Finance will be deemed not to be confidential
                                unless otherwise agreed upon in writing by the user and BMW Finance.</li>
                        </ol>
</li>
                            <li><strong>BREACH OR CANCELLATION</strong><br/>
                                <ol>
                            <li>BMW Finance is entitled, by notice to you and in addition to any other remedy available
                                to it legally or under these terms of use, including obtaining an interdict, to cancel
                                these terms of use and/or limit or deny such user the use of the website and services or
                                to claim specific performance of any obligation whether or not the due date for
                                performance has arrived, in either event without prejudice to BMW Finance’s right to
                                claim damages should any user:</li>
                            <li>Breach any of these terms of use;</li>
                            <li>In the sole discretion of BMW Finance, use the website in an unauthorised manner; or
                            </li>
                            <li>Infringe any statute, regulation, ordinance or law.</li>
                            <li>Breach of these terms of use entitles BMW Finance to take legal action and the user
                                agrees to reimburse the costs associated with such legal action to BMW Finance on an
                                attorney and own client scale.</li>
                        </ol>

                        </li>
                            <li><strong>COMPLIANCE WITH SECTION 43(1) OF THE ELECTRONIC COMMUNICATIONS AND TRANSACTIONS
                                    ACT 25 of 2002</strong><br/>
                            <p>In compliance with Section 43(1) of the ECT Act, the following is noted:</p>
                                    <ol>
                            <li>Full Name: BMW Financial Services (South Africa) (Pty) Ltd;</li>
                            <li>Registration Number: 1990/004670/07;</li>
                            <li>Physical Address: 1 Bavaria Avenue, Randjespark Ext 17, Midrand, 1685;</li>
                            <li>Fax Number: +27(0)11 541 7593;</li>
                            <li>Telephone Number: <a href="tel:+27800600555" target="_blank">0800 600 555</a></li>
                            <li>Website Address: <a href="https://www.alphera.co.za/"
                                    target="_blank">http://www.alphera.co.za</a></li>
                            <li>E-mail Address: <a href="mailto:customer.service@bmwfinance.co.za"
                                    target="_blank">customer.service@bmwfinance.co.za</a></li>
                            <li>Names of Office Bearers: *H Schlegel (Chairman), *S F Schuett (Chief Executive Officer),
                                *I Koeppel (Chief Financial Officer), D J Modise (Chief Human Resources Officer) and P J
                                van Binsbergen (Non-Executive).</li>
                            <li>Registered at: South Africa.</li>
                        </ol>

                        </li>
                            <li><strong>COMPLIANCE WITH LAWS</strong><br/>
                                <ol>
                            <li>You agree to comply with all applicable laws, statutes, ordinances and regulations
                                pertaining to your use of and access to this website.</li>
                        </ol>
                        </li>

                            <li><strong>NOTICES</strong><br/>
                                <ol>
                            <li>Except if otherwise stated, any notices shall be sent to you by e-mail at the e-mail
                                address you have provided BMW Finance or such other address that has been specified.
                            </li>
                            <li>Notice shall be deemed to have been given to you 48 (forty eight) hours after an e-mail
                                was sent, unless the sending party is notified that the e-mail address is invalid.</li>
                            <li>Alternatively BMW Finance may provide you with notice by registered mail, pre-paid
                                postage and return receipt requested, to the address which you have provided to BMW
                                Finance. In such a case, notice shall be deemed to have been given seven (7) days after
                                the date the notice was mailed.</li>
                            <li>You acknowledge that all agreements, notices or other communication required to be given
                                in terms of the law or these terms of use may be given by way of electronic means and
                                that such communications shall be in writing.</li>
                            <li>Despite anything to the contrary, a written notice or communication actually received by
                                a party shall be an adequate written notice or communication to it, despite that it was
                                not sent to or delivered at its chosen address(es) for that purpose.</li>
                        </ol>


                            </li>
                            <li><strong>GENERAL</strong><br/>
                                <ol>
                            <li>These terms of use shall be governed in all respects by the laws of the Republic of
                                South Africa as such laws are applicable to agreements entered into and to be performed
                                within South Africa.</li>
                            <li>This website is controlled, operated and administered by BMW Finance from its offices
                                within the Republic of South Africa. BMW Finance makes no representation that the
                                content of the website is appropriate or available for use outside the Republic of South
                                Africa. Access to the website from territories or countries where the content of the
                                website is illegal is prohibited. Users may not use this website in violation of South
                                African export laws and regulations. If the user accesses this website from locations
                                outside of South Africa, they shall be responsible for compliance with all local laws.
                            </li>
                            <li>BMW Finance does not guarantee continuous, uninterrupted or secure access to the
                                website, as operation of the website may be interfered with as a result of a number of
                                factors which are outside of our control.</li>
                            <li>If any provision of these terms of use is held to be illegal, invalid or unenforceable
                                for any reason, such provision shall be struck out from these terms of use and the
                                remaining provisions shall be enforced to the full extent of the law.</li>
                            <li>BMW Finance’s failure to act with respect to a breach by you or others does not
                                constitute a waiver of our right to act with respect to subsequent or similar breaches.
                            </li>
                            <li>You shall not be entitled to cede your rights or assign your rights or to delegate your
                                obligations in terms of these terms of use to any third party without the prior written
                                consent of BMW Finance to do so.</li>
                            <li>No party shall be bound by any express or implied term, representation, warranty,
                                promise or the like not recorded herein, whether it induced the contract and/or whether
                                it was negligent or not.</li>
                            <li>Words importing the singular shall include the plural and vice versa and words importing
                                the masculine gender shall include the female gender and words importing persons shall
                                include partnerships and corporated and unincorporated entities.</li>
                            <li>These terms of use do not constitute the entire agreement between BMW Finance and you
                                the user and only once you have concluded an instalment sale agreement will that
                                agreement constitute the entire agreement between you and BMW Finance.</li>
                        </ol>
                        </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>