<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="../assets/img/hero/legal.jpg" alt="ALPHERA PAIA & POPIA" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>PAIA & POPIA</h1>
                        <p>We appreciate your interest. Please take a moment to review the PAIA & POPIA Notice for the ALPHERA Financial Services Website. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/paia-and-popia">PAIA & POPIA</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>


    <section class="pTB accordionSec">
        <div class="container">
            <div class="containerWrapper">
               <h3>PROMOTION OF ACCESS TO INFORMATION.</h3>
               <p>
                The PAIA and POPI Acts give effect to everyone's constitutional right of access to information held by the private sector or public bodies – and ALPHERA Financial Services is no exception. We take the privacy and protection of your personal information very seriously and will only process personal information in accordance with the current South African privacy laws. If you would like to find out more, request access to your information or see our rates and fees – you have come to the right place.
               </p>
               <div class="accordion accordionWrapper" id="accordionLegalNotice">
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeOne" aria-expanded="true" aria-controls="accordion_LegalNoticeOne">
                            PAIA
                        </a>
                        <div id="accordion_LegalNoticeOne" class="accordion-collapse collapse show" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <div class="col-md-6 col-lg-5 btnWrapper">
                                    <a href="./assets/files/PAIA-manual.pdf" class="button button-g-blue btn-block" target="_blank">Download PAIA & POPIA Manual</a><br>
                                    <a href="./assets/files/Prescribed_Fees.pdf" class="button button-g-blue btn-block" target="_blank">Download PAIA Prescribed Fees</a><br>
                                    <a href="./assets/files/REQUEST_FOR_ACCESS_TO_RECORD.pdf" class="button button-g-blue btn-block" target="_blank">Download PAIA Application Form</a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeTwo" aria-expanded="false" aria-controls="accordion_LegalNoticeTwo">
                            PAIA Application Form and Prescribed Fees.
                        </a>
                        <div id="accordion_LegalNoticeTwo" class="accordion-collapse collapse show" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <div class="col-md-6 col-lg-5 btnWrapper">
                                    <a href="./assets/files/PAIA-manual.pdf" class="button button-g-blue btn-block">Download PAIA Manual</a><br>
                                    <a href="./assets/files/Prescribed_Fees.pdf" class="button button-g-blue btn-block" target="_blank">Download PAIA Prescribed Fees</a><br>
                                    <a href="./assets/files/REQUEST_FOR_ACCESS_TO_RECORD.pdf" class="button button-g-blue btn-block" target="_blank">Download PAIA Application Form</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeThree" aria-expanded="false" aria-controls="accordion_LegalNoticeThree">
                            Protection of Personal Information Act (POPIA)
                        </a>
                        <div id="accordion_LegalNoticeThree" class="accordion-collapse collapse" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <!--<p>
                                    The PAIA and POPI Acts give effect to everyone's constitutional right of access to information held by the private sector or public bodies – and ALPHERA Financial Services is no exception. We take the privacy and protection of your personal information very seriously and will only process personal information in accordance with the current South African privacy laws. If you would like to find out more, request access to your information or see our rates and fees – you have come to the right place.
                                </p>
                                <p>
                                    For any information pertaining to BMW Financial Services Privacy Policy please contact us in writing at <a href="mailto:Compliance-ZA@bmwfinance.co.za" target="_blank">Compliance-ZA@bmwfinance.co.za</a> or by calling our Customer Contact Centre on <a href="tel:+27800600555" target="_blank">0800 600 555</a>.
                                </p>
                                <p>
                                    BMW Financial Services (South Africa) (Pty) Ltd. is an Authorised Financial Services & Registered Credit Provider NCRCP2341.
                                </p>-->
                                <div class="col-md-6 col-lg-5 btnWrapper">
                                    <a href="./assets/files/Prescribed POPIA Form 1_ALPHERA.pdf" class="button button-g-blue btn-block" target="_blank">Download POPIA Form 1 – Objection to the Processing of Personal Information</a><br>
                                    <a href="./assets/files/Prescribed POPIA Form 2_ALPHERA.pdf" class="button button-g-blue btn-block" target="_blank">Download POPIA Form 2 – Request for Correction or Deletion of Personal Information</a><br>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </section>


</main>