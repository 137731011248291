<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/calculator.jpg" alt="ALPHERA Finance calculator" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Finance Calculator</h1>
                        <p>The finance calculator helps you estimate the monthly repayments on your dream car or
                            motorcycle.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/calculator">Calculator</a></li>
                    </ul>
                </div>
                <h3>Estimate your monthly repayments</h3>
                <p>Using the sliders, you can select the repayment period and (optional) balloon amount that works for
                    you. Next, enter the purchase price of the vehicle you’ve got your eye on, as well as your
                    (optional) deposit amount. Select your interest rate to easily estimate your monthly repayments and
                    (potential) balloon value.</p>
                <p>Please note: The calculator is an estimation tool only.</p>
            </div>
        </div>
    </section>

    <section class="calculatorSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row">
                    <div class="col-md-4">
                        <div class="cardBox bgGradientBlue">
                            <h5>Disclaimer</h5>
                            <p>Any information obtained on this calculator will not constitute a finance quote as
                                contemplated by the NCA and customers are reminded that any information regarding our
                                finance products are subject to change.</p>
                            <p>No responsibility for any loss occasioned to any person acting or refraining from action
                                as a result of material in this program is accepted by ALPHERA Financial Services.</p>
                            <p>An initiation fee and monthly fees will apply. Terms and Conditions apply.</p>
                            <p>ALPHERA Financial Services is a Division of BMW Financial Services (South Africa) (Pty)
                                Ltd., an Authorised Financial Services (FSP 4623) and Registered Credit Provider
                                (NCRCP2341).</p>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="calculatorWrapper">
                            <!-- <iframe src="https://forms.bmw.co.za/alphera-calculator/calculator.html"
                                frameborder="0"></iframe> -->
                            <div class="calculator">
                                <form [formGroup]="calcForm">
                                    <div class="formGroup">
                                        <label class="form-label">Repayment period*</label>
                                        <select name="periodSelector" id="" class="form-control"
                                            formControlName="periodSelector" (change)="periodChanged($event)">
                                            <option value="12">12 Months</option>
                                            <option value="24">24 Months</option>
                                            <option value="36">36 Months</option>
                                            <option value="48">48 Months</option>
                                            <option value="60">60 Months</option>
                                            <option value="72">72 Months</option>
                                        </select>
                                    </div>
                                    <div class="formGroup">
                                        <label for="balloonPercent" class="form-label">Balloon percentage</label>
                                        <div class="groupRangProgressBar">
                                            <div class="progress" role="progressbar" aria-label="Example with label"
                                                aria-valuenow="3" aria-valuemin="0" aria-valuemax="40">
                                                <div class="progress-bar" [style.width.%]="calculatePercent()">
                                                    {{rangeValue}}%</div>
                                            </div>
                                            <span class="ballIndicator" [style.left.%]="calculateIndicator()"></span>
                                            <input type="range" class="fomr-range" name="balloonSelector"
                                                formControlName="balloonSelector" (change)="balloonChanged($event)"
                                                min="0" max="40" step="1" [(ngModel)]="rangeValue">
                                        </div>
                                        <span class="indicatorsWrapper"><small>0%</small> <small>40%</small></span>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 formGroup">
                                            <label for="purchasePrice" class="form-label">Purchase price* (ZAR)</label>
                                            <input type="text" placeholder="" name="purchasePrice"
                                                [ngClass]="{ 'error': submitted && f['purchasePrice'].errors }"
                                                formControlName="purchasePrice" class="form-control"
                                                (change)="priceChanged($event)">
                                            <span *ngIf="submitted && f['purchasePrice'].errors"
                                                class="help-block form-error">
                                                <span *ngIf="f['purchasePrice'].errors['pattern']">Invalid
                                                    amount</span>
                                            </span>
                                            <small>Purchase Price minimum value of R50 000.00</small>
                                        </div>

                                        <div class="col-md-6 formGroup">
                                            <label for="totalDeposit" class="form-label">Deposit / Trade in value*
                                                (ZAR)</label>
                                            <input type="text" placeholder="" name="totalDeposit"
                                                [ngClass]="{ 'error': submitted && f['totalDeposit'].errors }"
                                                formControlName="totalDeposit" class="form-control"
                                                (change)="depositChanged($event)">
                                            <span *ngIf="submitted && f['totalDeposit'].errors"
                                                class="help-block form-error">
                                                <span *ngIf="f['totalDeposit'].errors['pattern']">Invalid
                                                    amount</span></span>
                                        </div>

                                    </div>
                                    <div class="formGroup mb-5">
                                        <label for="interestRate" class="form-label">Interest rate*</label>
                                        <select name="interestRate" id="interestRate" class="form-control"
                                            formControlName="interestRate" (change)="interestChanged($event)">
                                            <option value="0">Prime</option>
                                            <option value="1" [selected]>Prime + 1</option>
                                            <option value="2">Prime + 2</option>
                                            <option value="3">Prime + 3</option>
                                            <option value="4">Prime + 4</option>
                                        </select>
                                    </div>

                                    <div class="row sumSec">
                                        <div class="col-6">
                                            <h3>{{totalRepaymentsValue | currency : "R"}}
                                                <small>Monthly repayment</small>
                                            </h3>
                                        </div>
                                        <div class="col-6 boderLeft">
                                            <h3>{{totalBalloonValue | currency: 'R' }}
                                                <small>Balloon value</small>
                                            </h3>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bgMarineBlue productHighlight">
        <div class="container">
            <div class="containerWrapper">
                <h2>Our finance products</h2>
                <div class="row">
                    <div class="col-6">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/instalment-sale.jpg" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="teaserDescription">
                                <h3>Instalment Sale</h3>
                                <h4>Four ways to customise your contract</h4>
                                <p>Our flexible instalment sale plan allows you to drive away in your dream car. At the
                                    end of your term, you decide if you want to keep the vehicle, sell it, or trade it
                                    in.</p>
                                <a routerLink="/finance/instalment-sale" class="simpleLink">Learn more </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/edge.jpg" alt="" class="img-fluid">
                            </div>
                            <div class="teaserDescription">
                                <h3>Edge</h3>
                                <h4>The best of both</h4>
                                <p>Edge is an innovative 50:50 payment plan which gives you the benefit of not having to
                                    deplete your savings or withdraw from your bond to purchase the car of your dreams
                                    in cash.</p>
                                <a routerLink="/finance/edge" class="simpleLink">Learn more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="bgPattern bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5">
                        <img src="assets/img/insurance.jpg" alt="Car finance built on quality" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pS">
                            <h2>ALPHERA Insurance Cover</h2>
                            <p>Now, ALPHERA customers who already enjoy our efficiency and quality of service in motor
                                vehicle finance can also enjoy cover that goes one step further with our extensive range
                                of insurance solutions.</p>

                            <a routerLink="/insurance" class="button button-g-blue">Explore Insurance </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>