
<div class="cardBox bgWhite contact">
    <h4>Top Up, Tyre Cover, and Extended Warranty</h4>
    <h5>For all general enquiries or questions about your cover</h5>
    <ul>
        <li><i class="icon icon--phone"></i><span><a href="tel:+27860100269">0860 100 269</a> (8.00am to 7.00pm Monday - Friday; 9.00am to 5.00pm Saturday)</span> </li>
        <li><i class="icon icon--email"></i> <span>Request a Quote: <a href="mailto:bmwfsreferrals@innovation.group" target="_blank">bmwfsreferrals@innovation.group</a></span> </li>
        <li><i class="icon icon--email"></i> <span>Amendments and claims to Active Policies: <a href="mailto:bmw@innovation.group" target="_blank">bmw@innovation.group</a></span> </li>
    </ul>
    <a (click)="goToProduct(incomingProduct)" class="button button-g-blue" *ngIf="showBtn==='Yes'">Complete Contact Form</a>
</div>