<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/terms-conditions.jpg" alt="Webchat Application Terms and Conditions" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Webchat Application Terms and Conditions</h1>
                        <p>By visiting the ALPHERA website, you agree to the Webchat Application Terms and Conditions of use.
                            Please take a moment to read through Webchat Application Terms & Conditions and familiarise yourself
                            with its contents. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/webchat-terms">Webchat Terms & Conditions</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </section>


    <section class="pTB bgWhite">
        <div class="container">

            <div class="containerWrapper">
                        <ol>
                            <li>ALPHERA Financial Services, a division of BMW Financial Services (South Africa) (Pty) Ltd herein after referred to as (“ALPHERA Finance”) have implemented a Webchat interaction channel on the ALPHERA Finance website. This interaction channel allows Customers to interact directly with ALPHERA Finance.</li>
                            <li>ALPHERA Finance strives to comply with the Protection of Personal Information Act, 4 of 2013 (POPI), by ensuring that its Customers’ personal information is dealt with in a reasonable manner that does not infringe the Customer’s privacy and in accordance with the provisions of POPI.</li>
                            
                            <li>To this extent, ALPHERA Finance requires the Customers’ consent to process the personal information that may be provided through use of the Webchat Application. This processing of personal information includes but is not limited to the collecting, recording, organising, storing, updating, altering and use of your personal information so provided for the following purposes:<br/>
                                <ol>
                            <li>Use of the Webchat Application;</li>
                            <li>To help ALPHERA Finance identify the Customers when they contact ALPHERA Finance;</li>
                            <li>Complaints Management;</li>
                            <li>Vehicle Data Management;</li>
                            <li>For general administration;</li>
                            <li>To help ALPHERA Finance improve the quality of its products and services;</li>
                            <li>To carry out analysis and customer profiling; and</li>
                            <li>To identify other products and services which may be of interest to the Customers and to inform the Customers about ALPHERA Finance’s products and services.</li>
                        </ol>
                            </li>
                            <li>Please be advised that personal information submitted through the Webchat Application may be sent outside South African borders and within the BMW Group where it will be processed, recorded, stored and replicated. Furthermore, personal information will be shared within the BMW Group and to BMW’s affiliates and/or subsidiaries.</li>
                            <li>Your attention is further drawn to the provisions of POPI in terms of which you may withdraw the consent so given to ALPHERA Finance. You may (after providing adequate proof of identity) make enquiries as to your personal information held by ALPHERA Finance and request a correction or deletion, as applicable.</li>
                            <li>You are also advised and acknowledge that for your protection and for quality purposes all calls and interactions on the Webchat channel are recorded and stored.</li>
                            <li>ALPHERA Finance strives to ensure that its website and/or Webchat Application is secure. However, please be advised that when Customers provide personal information to ALPHERA Finance through the website, use of the Webchat Application or when Customers send ALPHERA Finance confidential and personal information by e-mail or through electronic communications or other means, such communications over the internet may not be secure. ALPHERA Finance may not be held responsible for any loss or unauthorised interception of information transmitted via the internet which is beyond its control. ALPHERA Finance’s website may contain links to other websites outside of the BMW Group. ALPHERA Finance has no control over and accepts no responsibility for the content of any external website. ALPHERA Finance is not responsible for the content, privacy or security of external websites.</li>
                            <li>In making use of the Webchat Application, you agree that you will, at all times, not share your unique username and password with any third party, including an ALPHERA Finance representative who may assist you through the Webchat channel. You further agree that ALPHERA Finance and the ALPHERA Finance representative will not be liable to you or any third party or take responsibility for any Offer/s accepted by you on the website which may have involved the assistance of the ALPHERA Finance representative. You acknowledge that at all material times you remain responsible for all actions executed on the website.</li>
                        </ol>
                    </div>
        </div>
    </section>
</main>