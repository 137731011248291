import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private _renderer2: Renderer2;
  constructor(private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document
  ) { 
    this._renderer2 = rendererFactory.createRenderer(null, null);
  }

  setPageName(pageName: string) {
    let script = this._renderer2.createElement('script');
    script.text = `window.page_name= ${pageName}`;
    this._renderer2.appendChild(this._document.body, script);
  }
}
