<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/top-up.jpg" alt="ALPHERA Top Up Cover" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Top Up Cover</h1>
                        <p>Enjoy three cover options to suit your individual needs, from Top Up Core cover, to tradition
                            Top Up cover and Top Up cover over R1.5 m</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/insurance">Insurance</a></li>
                        <li><a routerLink="/insurance/top-up-cover">ALPHERA Top Up Cover</a></li>
                    </ul>
                </div>
                <!-- <h3>Protect yourself from vehicle finance settlement shortfalls</h3>
                <p>Ensure nothing interrupts your journey with insurance engineered to protect you from vehicle finance settlement shortfalls. If your vehicle is stolen or written off, we’ll settle the difference between your comprehensive insurance pay-out and the amount owing on your credit agreement.</p> -->
            </div>
        </div>
    </section>


    <section class="pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="row mb-5">
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/top-up-core.jpg" alt="Top Up Core"
                                    class="img-fluid">
                            </div>
                            <div class="teaserDescription">
                                <h3>Option 1: Top Up Core</h3>
                                <p>Enjoy a simpler, no-frills version of our traditional Top Up Core cover. Top Up Core
                                    gives you peace-of-mind with essential cover where you need it most. What you get:
                                </p>
                                <ul>
                                    <li>Covers vehicle amounts greater than R50,000.</li>
                                    <li>Maximum benefit – R600 000 shortfall.</li>
                                    <li>This product does not include supplementary cover.</li>
                                </ul>




                                <p><strong>Product Option</strong></p>
                                <ul>
                                    <li>TOP UP CORE R0 – R100 000</li>
                                    <li>TOP UP CORE R100 001 – R200 000</li>
                                    <li>TOP UP CORE R200 001 – R300 000</li>
                                    <li>TOP UP CORE R300 001 – R600 000</li>
                                </ul>

                                <a href="javascript:void(0);" (click)="goToProduct('Top Up Cover')"
                                    class="button button-g-blue">Request Quote</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/top-up-option.jpg" alt="Top Up"
                                    class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>Option 2: Top Up</h3>
                                <ul>
                                    <li>Vehicle amounts greater than R50,000 and less than R1,5 million.</li>
                                    <li>Maximum benefit – R300 000 shortfall. R6000 excess benefit towards underlying
                                        excess in the event of a valid shortfall claim. The maximum amount paid out,
                                        will be determined by your underlying Comprehensive insurance over on your
                                        vehicle.</li>
                                    <li>Supplementary cover included in the event that you unintentionally violate the
                                        underlying comprehensive policy – You will need to submit an Ombudsman request
                                        against the underlying Insurer first.</li>
                                    <li>Deposit protector (Optional cover only applicable if a deposit was paid).
                                        Deposit protection up to R100 000.</li>
                                </ul>
                                <a href="javascript:void(0);" (click)="goToProduct('Top Up Cover')"
                                    class="button button-g-blue">Request Quote</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="teaserItem">
                            <div class="teaserImg"><img src="./assets/img/teasers/top-up-over.jpg"
                                    alt="Top Up over R1,5m" class="img-fluid"></div>
                            <div class="teaserDescription">
                                <h3>Option 3: Top Up over R1,5m</h3>
                                <ul>
                                    <li>Vehicle amount that exceed R1,500,000 to a maximum of R2,500,000 in value.</li>
                                    <li>
                                        Maximum benefit of R600 000 shortfall.</li>
                                </ul>
                                <a href="javascript:void(0);" (click)="goToProduct('Top Up Cover')"
                                    class="button button-g-blue">Request Quote</a>
                            </div>
                        </div>
                    </div>
                </div>

                <h2>Downloads</h2>
                <p>Enjoy quick access to your cover documents. Find all the product information you need in thr
                    brochure. Existing customers can also download a claim form, online. </p>
                <div class="downloadBtnWrapper"><a href="./assets/files/ALPHERATopUpCover2023.pdf"
                        class="button button-ghost" target="_blank">Donwload Brochure</a>
                    <a href="./assets/files/716573_ALPHERA_Top_Up_Claim_Form.pdf" class="button button-ghost"
                        target="_blank">Download Claim form</a>
                </div>
            </div>
        </div>
    </section>


    <section class="bgPattern pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <app-insurance-teasers [insuranceTypeToChild]="'top-up-cover'"></app-insurance-teasers>
            </div>
        </div>
    </section>

    <section class="bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Need a quote or support?</h3>
                <p>Whether it’s a custom quote for the insurance product you have your eye on, or you need a support
                    from our experienced insurance team, we are here to assist you on your journey. Please don’t
                    hesitate to get in touch for any questions, enquires or feedback. We are looking forward to hearing
                    from you.
                </p>

                <div class="col-md-6">
                    <app-top-up-tyre-rim-extended [showBtn]="'Yes'"
                        [incomingProduct]="'Top Up Cover'"></app-top-up-tyre-rim-extended>
                </div>
            </div>
        </div>
    </section>

</main>