import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { QuoteServiceService } from 'src/app/_services/quote-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.less']
})
export class RequestQuoteComponent implements OnInit {

  constructor(private quoteSvc: QuoteServiceService, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) { }

  iframeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  incomingProduct: any;
  iframeIsLoading: boolean = false;
  messageStus: any = {};
  ngOnInit(): void {
    this.setUrl();
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  setUrl() {
    this.incomingProduct = sessionStorage.getItem("_ins_prod");
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl( environment.formUrl +'/?brand=alphera&product=' + this.incomingProduct);

  }

  handleMessage(event: MessageEvent){
    let res = event.data; 
    if(res.status==='success'){
    this.messageStus = res;
    this.cdr.markForCheck();
    }
    
    if(res.status==='failed'){
      this.messageStus = res;
      this.cdr.markForCheck();
      }
  }

  myLoadEvent() {
    this.iframeIsLoading = false;
  }
}
