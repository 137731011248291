<footer>
    <div class="container">
        <div class="containerWrapper">
            <div class="row topFooter">
                <div class="col-sm-3"><img src="./assets/img/logo--white.svg" alt="" class="logoImg"></div>
                <div class="col-sm-6">
                    <h4>Explore </h4>
                    <div class="footerNav">
                        <a routerLink="/">Home </a>
                        <a routerLink="/finance">ALPHERA Finance Products</a>
                        <a routerLink="/insurance">ALPHERA Insurance Cover </a>
                        <a routerLink="/dealers">Become a partner</a>
                        <a routerLink="/auctions">Auctions</a>
                        <a routerLink="/faqs">FAQs</a>
                        <a routerLink="/contact-us">Contact us</a>
                    </div>
                </div>
                <div class="col-sm-3">
                    <h4>Follow us</h4>
                    <div class="socialNav">
                        <a href="https://www.facebook.com/AlpheraFinancialServices" class="socialItem" target="_blank"><img src="./assets/img/icons/facebook.svg" alt="Alphera Finance Facebook" class="imgIcon" /></a>
                        <a href="https://www.instagram.com/alphera_finance/" class="socialItem" target="_blank"><img src="./assets/img/icons/instagram.svg" alt="Alphera Finance Instagram" class="imgIcon" /></a>
                    </div>
                </div>
            </div>

            <div class="bottomFooter">
                <div class="copyRight">© {{currentYear}} ALPHERA Financial Services</div>
                <ul class="nav legalNav">
                    <li><a routerLink="/terms-conditions">Terms and Conditions</a></li>
                    <li><a routerLink="/privacy-statement">Privacy Statement</a></li>
                    <li><a routerLink="/paia-and-popia">PAIA & POPIA</a></li>
                    <li><a routerLink="/legal-notice">Legal Notice</a></li>
                    <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>