<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/auctions.jpg" alt="ALPHERA Used Car Auctions" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Used Car Auctions</h1>
                        <p>Vehicles on auction can be purchased by individual customers, ALPHERA dealers and other
                            interested parties.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/auctions">Auctions</a></li>
                    </ul>
                </div>
                <h3>Get your dream vehicle on auction</h3>
                <p>Public auctions of repossessed vehicles take place at various venues. These auctions are managed by
                    independent auctioneers which have been listed below. Vehicles on auction can be purchased by
                    individual customers, ALPHERA dealers and other interested parties.</p>
                <p>ALPHERA Financial Services in conjunction with the below mentioned auctioneers host physical auctions
                    on a regular basis. For more information on the auction dates and auction please contact. Click on the icons below to get more information.</p>
            </div>
        </div>
    </section>
    <section class="partnersSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="brandsWrapper">
                    <a href="https://www.bidvestburchmores.co.za/" target="_blank" class="brandItem"><img src="assets/img/partners/Bidvest-Burchmores.png"
                            alt="Bidvest-Burchmores"></a>
                    <a href="https://live.auctionoperation.co.za/" target="_blank" class="brandItem"><img src="assets/img/partners/auctionmobility.png" alt="auction mobility">
                    </a>
                    <a href="https://www.michaeljames.co.za/" target="_blank" class="brandItem"><img src="assets/img/partners/Micheal-James-organsations.png"
                            alt="Micheal James Organsations"></a>
                    <a href="https://www.parkvillageauctions.co.za/" target="_blank" class="brandItem"><img src="assets/img/partners/park-village-auctions.png"
                            alt="Park Village Auctions"></a>
                    <a href="https://www.dealersonline.co.za/" target="_blank" class="brandItem"><img src="assets/img/partners/dealers-online.png" alt="Dealers Online"></a>
                </div>
            </div>
        </div>
    </section>

    <section class="pTB">
        <div class="container">
            <div class="containerWrapper">
                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5 order-md-1">
                        <img src="assets/img/auctions.jpg" alt="ALPHERA Finance" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pE">
                            <h2>Auction Process</h2>
                            <ol>
                                <li>Identify a vehicle from the auction lists.</li>
                                <li>
                                    Viewing of vehicles is available the day before each auction from 8:00 - 16:00, at
                                    the specified auction venue (refer to auctioneers website for further information).
                                </li>
                                <li>
                                    Customers may apply for <a routerLink="/finance">finance online</a> before the auction or
                                    alternatively contact ALPHERA Connect on 0861 257 437.</li>
                                <li>
                                    Review documents required for a personal finance application on the <a
                                        routerLink="/faqs">FAQs page</a>.</li>
                                <li>
                                    A registration fee to participate in each auction will be payable on the day of the
                                    auction, which on successful purchase, contributes towards the purchase price of the
                                    vehicle.</li>
                                <li>
                                    FICA compliance is a requirement to participate in any physical or online auction.
                                </li>
                                <li>
                                    All vehicles financed through ALPHERA Financial Services are registered in the name
                                    of BMW Financial Services.</li>
                                <li>
                                    Vehicles are sold as is (voetstoets).</li>
                                <li>
                                    Please ensure that you have your original ID document during collection of the
                                    vehicle.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="bgPattern bottomSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Financing your vehicle</h3>
                <p>ALPHERA Financial Services provide vehicle finance for vehicles purchased on auction. Customers may
                    apply for finance online before the auction or alternatively contact ALPHERA Connect on <a
                        href="tel:+27861257437" target="_blank">0861 257 437</a> or via email on <a
                        href="mailto:ALPHERAConnect@ALPHERAfinance.co.za"
                        target="_blank">ALPHERAConnect@ALPHERAfinance.co.za</a> for assistance on an application.</p>
                <a href="https://app.bmwfinance.co.za/sf/ALPHERAOnlCreditApp/index.html" target="_blank" class="button button-g-blue">Apply for finance online</a>
            </div>
        </div>
    </section>

</main>