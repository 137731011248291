<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="../assets/img/hero/legal.jpg" alt="ALPHERA Legal Notice" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>Legal Notice</h1>
                        <p>We appreciate your interest. Please take a moment to review the Legal Notice for the ALPHERA Financial Services Website. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal-notice">Legal Notice</a></li>
                    </ul>
                </div>
                
            </div>
        </div>
    </section>


    <section class="pTB accordionSec">
        <div class="container">
            <div class="containerWrapper">
               <h3>Legal Notice</h3>
               <div class="accordion accordionWrapper" id="accordionLegalNotice">
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeTwo" aria-expanded="false" aria-controls="accordion_LegalNoticeTwo">
                            E-mail legal disclaimer.
                        </a>
                        <div id="accordion_LegalNoticeTwo" class="accordion-collapse collapse" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <ol>
                                    <li>Email messages and any attachments are confidential and intended solely for the addressee. If you have received this message in error, please notify the sender from BMW Financial Services from which this message originates immediately. Any unauthorised use, alteration or dissemination is prohibited.</li>
                                    <li>BMW Financial Services (South Africa) (Pty) Ltd (Registration Number 1990/004670/07), accepts no liability whatsoever for any loss whether it be direct, indirect or consequential, arising from information made available and actions resulting there from.</li>
                                    <li>Please note that BMW Financial Services (South Africa) (Pty) Ltd only bind themselves by way of signed agreements. 'Signed' refers to a hand-written signature, excluding any signature appended by 'electronic communication' as defined in the Electronic Communications and Transactions Act, no. 25 of 2002.</li>
                                </ol>
                                <div class="col-md-5 col-lg-3 btnWrapper">
                                <a routerLink="/terms-conditions" class="button button-g-blue">Terms and Conditions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeThree" aria-expanded="false" aria-controls="accordion_LegalNoticeThree">Directors List.</a>
                        <div id="accordion_LegalNoticeThree" class="accordion-collapse collapse" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <p>BMW Financial Services (South Africa) (Pty) Ltd. with the following particulars:<br><br>

                                Board of Directors:<br><br>

                                L Y L Ng* (Chairwoman)<br>
                                S F Schuett** (Chief Executive Officer)<br>
                                R G Rooden*** (Chief Financial Officer)<br>
                                D J Modise<br>
                                C C Pillay<br>
                                T Moodally<br><br>

                                *Chinese<br>
                                **German<br>
                                ***Dutch<br>
                                BMW Financial Services (South Africa) (Pty) Ltd<br>
                                BMW Group Company<br><br>

                                ALPHERA Financial Services is a Division of BMW Financial Services (South Africa) (Pty) Ltd, an Authorised Financial Services Provider (FSP 4623) and Registered Credit Provider (NCRCP2341).</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeFour" aria-expanded="false" aria-controls="accordion_LegalNoticeFour">National Credit Act (NCA) FAQs.</a>
                        <div id="accordion_LegalNoticeFour" class="accordion-collapse collapse" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <p>BMW Financial Services (South Africa) (Pty) Ltd is a Registered Credit Provider (NCRCP2341)</p>


                                    <p>The National Credit Regulator (NCR) handles complaint with regards to NCA compliance.
                                    Their contact details are as follows:
                                </p>
                                <p>
                                    <strong>Tel</strong>: <a href="tel:+27860627627" target="_blank">0860 627 627</a><br>
                                    <strong>Email</strong>: <a href="mailto:complaints@ncr.org.za" target="_blank">complaints@ncr.org.za</a><br>
                                    <strong>Website</strong>: <a href="https://www.ncr.org.za" target="_blank">www.ncr.org.za</a></p>
                                    <div class="col-md-6 col-lg-5 btnWrapper">
                                <a href="./assets/files/Language-Policy.pdf" class="button button-g-blue" target="_blank">Download Language Policy.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeFive" aria-expanded="false" aria-controls="accordion_LegalNoticeFive">FAIS</a>
                        <div id="accordion_LegalNoticeFive" class="accordion-collapse collapse" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <p><strong>FAIS Disclosure Notice</strong>.</p>
                                <p>
    BMW Financial Services (South Africa) (Pty) Ltd is an Authorised Financial Services Provider, FSP 4623</p>
                            </div>
                        </div>
                    </div>
                
                    <div class="accordion-item accordionItem">
                        <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed" data-bs-toggle="collapse" data-bs-target="#accordion_LegalNoticeSix" aria-expanded="false" aria-controls="accordion_LegalNoticeSix">
                            Conflict of Interest Policy
                        </a>
                        <div id="accordion_LegalNoticeSix" class="accordion-collapse collapse" data-bs-parent="#accordionLegalNotice">
                            <div class="accordion-body accordionBody">
                                <p>In compliance with the General Code of Conduct for Authorised Financial Services Providers and Representatives, 
                                    this notice serves to inform you that the BMW Financial Services Conflict of Interest Management Policy may be obtained through contacting <a href="mailto:Compliance-ZA@bmwfinance.co.za" target="_blank">Compliance-ZA@bmwfinance.co.za</a>.</p>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </section>


</main>