<div class="row">
    <div class="col-md-6 col-lg-4">
        <div class="cardBox bgGradientBlue">
            <h5>More ALPHERA Insurance covers</h5>
            <p>Browse the range of cover options available to all ALPHERA Financial Services customers. From comprehensive insurance to specific cover and the warranty on your vehicle - we’ve got you covered.  </p>
        </div>
    </div>
    <div class="col-md-6 col-lg-4" *ngIf="insuranceTypeToChild !=='comprehensive-cover'">
        <div class="teaserItem">
            <div class="teaserImg"><img src="./assets/img/teasers/comprehensive-cover.jpg"
                    alt="ALPHERA Comprehensive Cover" class="img-fluid">
            </div>
            <div class="teaserDescription">
                <h3>ALPHERA Comprehensive Cover</h3>
                <p>ALPHERA Comprehensive Cover has been specifically designed to offer you complete protection and peace
                    of mind. You are ensured of full comprehensive cover.</p>
                <a routerLink="/insurance/comprehensive-cover" class="simpleLink">Learn more </a>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-lg-4" *ngIf="insuranceTypeToChild !=='top-up-cover'">
        <div class="teaserItem">
            <div class="teaserImg"><img src="./assets/img/teasers/top-up-cover.jpg" alt="ALPHERA Top-Up Cover"
                    class="img-fluid"></div>
            <div class="teaserDescription">
                <h3>ALPHERA Top-Up Cover</h3>
                <p>Ensure nothing interrupts your journey with insurance engineered to protect you from vehicle finance
                    settlement shortfalls. </p>
                <a routerLink="/insurance/top-up-cover" class="simpleLink">Learn more</a>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-lg-4"  *ngIf="insuranceTypeToChild !=='care-cosmetic-repair-cover'">
        <div class="teaserItem">
            <div class="teaserImg"><img src="./assets/img/teasers/care-cosmetic-repair-cover.jpg"
                    alt="ALPHERA Care & Cosmetic Repair Cover" class="img-fluid"></div>
            <div class="teaserDescription">
                <h3>ALPHERA Care & Cosmetic Repair Cover</h3>
                <p>Small scratches, dents, chips and abrasions are all too common. You could let these blemishes ruin
                    your car’s appearance, or choose this innovative cover that gives you access to experts in cosmetic
                    repairs.</p>
                <a routerLink="/insurance/care-cosmetic-repair-cover" class="simpleLink">Learn more</a>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-lg-4"  *ngIf="insuranceTypeToChild !=='tyre-rim-cover'">
        <div class="teaserItem">
            <div class="teaserImg"><img src="./assets/img/teasers/tyre-rim-cover.jpg" alt="ALPHERA Tyre & Rim Cover"
                    class="img-fluid"></div>
            <div class="teaserDescription">
                <h3>ALPHERA Tyre & Rim Cover</h3>
                <p>Life's journey sometimes leaves its mark on your vehicle and in many instances tyres bear the brunt
                    of misfortune. </p>
                <a routerLink="/insurance/tyre-rim-cover" class="simpleLink">Learn more</a>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-lg-4" *ngIf="insuranceTypeToChild !=='extended-warranty-cover'">
        <div class="teaserItem">
            <div class="teaserImg"><img src="./assets/img/teasers/extended-warranty-cover.jpg" alt="" class="img-fluid">
            </div>
            <div class="teaserDescription">
                <h3>ALPHERA Extended Warranty Cover</h3>
                <p>Driving is one of life’s pleasures however heavy mileage and asset ageing may cause mechanical damage
                    to your most prized possession.</p>
                <a routerLink="/insurance/extended-warranty-cover" class="simpleLink">Learn more</a>
            </div>
        </div>
    </div>
</div>