<main>
    <section class="heroHeader pb-md-5">
        <div class="customContainer container-fluid heroContainer">
            <img src="./assets/img/hero/comprehensive.jpg" alt="ALPHERA Comprehensive Cover" class="img-fluid">
            <div class="container">
                <div class="containerWrapper position-relative">
                    <div class="heroCopy">
                        <h1>ALPHERA Comprehensive Cover</h1>
                        <p>ALPHERA Comprehensive Cover has been specifically designed to offer you complete protection
                            and ultimate peace of mind. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="introSec bgPattern">
        <div class="container">
            <div class="containerWrapper">
                <div class="breadCrumb">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/insurance">Insurance</a></li>
                        <li><a routerLink="/insurance/comprehensive-cover">ALPHERA Comprehensive Cover</a></li>
                    </ul>
                </div>
                <!-- <h3>Ultimate peace of mind</h3>
                <p>Your vehicle is one of your most prized possessions and worthy of protection, which is why ALPHERA
                    Comprehensive insurance covers you in the event of the unexpected. As the most extensive type of
                    insurance we offer, it is a natural choice is you are looking for a complete solution to bring you
                    the ultimate peace of mind.</p> -->
            </div>
        </div>
    </section>

    <section class="pTB">
        <div class="container">
            <div class="containerWrapper">
                <div class="row justify-content-between align-items-center teaserHorizontal">
                    <div class="col-md-5 order-md-1">
                        <img src="assets/img/protected.jpg" alt="Complete Protection" class="img-fluid">
                    </div>
                    <div class="col-md-7">
                        <div class="teaserCopy pE">
                            <h3>Complete Protection</h3>.
                            <p>ALPHERA Comprehensive Cover is committed to providing you with excellent countrywide
                                service that includes:</p>
                            <ul>
                                <li>An exceptional and exclusive motor vehicle insurance package.</li>
                                <li>Fair and accurate claims service.</li>
                                <li>Carefully selected products to suit your needs.</li>
                            </ul>
                            
                            <a href="javascript:void(0);" (click)="goToProduct('Comprehensive Cover')" class="button button-g-blue mb-3">Request Quote</a>
                            <a href="./assets/files/720379ALPHERAComprehensiveInsuranceBrochure_02.pdf" class="button button-ghost" target="_blank">Download Brochure</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bgPattern pTB teasersSec">
        <div class="container">
            <div class="containerWrapper">
                <app-insurance-teasers [insuranceTypeToChild]="'comprehensive-cover'"></app-insurance-teasers>
            </div>
        </div>
    </section>

    <section class="bottomTeaserSec">
        <div class="container">
            <div class="containerWrapper">
                <h3>Need a quote or support?</h3>
                <p>Whether it’s a custom quote for the insurance product you have your eye on, or you need a support
                    from our experienced insurance team, we are here to assist you on your journey. Please don’t
                    hesitate to get in touch for any questions, enquires or feedback. We are looking forward to hearing
                    from you.</p>

                <div class="col-md-6">
                    <app-comprehensive [showBtn]="'Yes'"></app-comprehensive>
                </div>
            </div>
        </div>
    </section>

</main>